import {
  AxiosError,
  default as axiosActual,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios';
import type { APIResponseErrorAs200 } from '../__model__/api/response/error-as-200-clothing.model';
import type { ServerClassification } from '../__model__/enum/server-classification';
import { default as urlServerTypeActionMap } from './url-server-type-action-map.json';

interface URLDefinition {
  method?: 'get' | 'post';
  url?: string;
  authRequired?: boolean;
}

type LookupURLPathAndMethodDef = Record<
  ServerClassification,
  Record<string, URLDefinition>
>;

const getUrlDef = ({
  action,
  serverType,
}: { action?: string; serverType?: ServerClassification } = {}) =>
  serverType && action
    ? (urlServerTypeActionMap as LookupURLPathAndMethodDef)[serverType][
        action
      ] ?? {}
    : {};

const pathAndMethodInterceptor = (config: AxiosRequestConfig<unknown>) => {
  try {
    const {
      url: suppliedUrl,
      method: suppliedMethod,
      params = {},
      ...untouchedConfig
    } = config;

    const { url = suppliedUrl, method = suppliedMethod } = getUrlDef(params);

    if (!url?.trim().length) {
      throw new AxiosError(
        'Unabled to resolve serverType and action to a url',
        '500',
        config,
      );
    }

    return {
      ...untouchedConfig,
      url,
      method,
      params,
    };
  } catch {
    throw new AxiosError(
      'Unabled to resolve serverType and action to a url',
      '500',
      config,
    );
  }
};

const replacePlaceholdersInterceptor = (
  config: AxiosRequestConfig<unknown>,
) => {
  const {
    params: suppliedParam = {},
    url: suppliedUrl = '',
    ...untouchedConfig
  } = config;
  const seen = new Set<string>();

  /**
   * replace the placeholders :key and {key} that match the patterns
   * - `{key}`
   * - `:key/`
   * - `:key<end of string>`
   */
  const url = suppliedUrl.replace(
    /\{(.*?)\}|:(.*?)(?=\/|$)/g,
    (match: string, ...rest) => {
      /**
       * find the first string in the list of grouped regions
       */
      const key = rest.find((val) => typeof val === 'string');
      seen.add(key);
      return encodeURI(suppliedParam[key] ?? '');
    },
  );

  /**
   * these keys may be needed latter and will be
   * removed later.
   */
  seen.delete('action');
  seen.delete('serverType');

  const params = { ...suppliedParam };
  seen.forEach((key) => delete params[key]);

  return {
    ...untouchedConfig,
    url,
    params,
  };
};

interface SetAuthorizationHeaderInterceptorProps {
  getAuthToken?: () => Promise<string | void>;
  getIsAuthenticated?: () => Promise<boolean>;
}

const setAuthorizationHeaderInterceptor =
  ({
    getAuthToken = () => Promise.resolve(''),
    getIsAuthenticated = () => Promise.resolve(false),
  }: SetAuthorizationHeaderInterceptorProps = {}) =>
  async (
    config: AxiosRequestConfig<unknown>,
  ): Promise<AxiosRequestConfig<unknown>> => {
    const { headers: suppliedHeaders = {}, ...untouchedConfig } = config;

    /**
     * NOTE: In order for this to get the value correctly
     * this needs to be one of the last interceptors
     * bound to axios.   See note below where inceptors are bound.
     */
    const { authRequired = true } = getUrlDef(config.params);

    const { Authorization: suppliedAuthorization, ...untouchedHeaders } =
      suppliedHeaders;

    const token: string = await getIsAuthenticated()
      .then(async (isAuthenticated) => {
        if (typeof isAuthenticated !== 'boolean' || !isAuthenticated) {
          // throw the error to go straight to the .catch
          throw new Error('user is not authenticated');
        }
      })
      .then(async () => (authRequired ? (await getAuthToken()) ?? '' : ''))
      .catch(async () => '');

    const authorization =
      typeof token === 'string' && token.trim().length
        ? `Bearer ${token}`
        : suppliedAuthorization;

    if (
      authRequired &&
      (typeof authorization !== 'string' ||
        authorization.trim().length === 0 ||
        authorization.trim() === 'Bearer')
    ) {
      throw new AxiosError(
        'Requested action requires authentication',
        '601',
        config,
      );
    }

    return {
      ...untouchedConfig,
      headers: {
        ...untouchedHeaders,
        Authorization: authorization,
      },
    };
  };

const setBaseUrlInterceptor =
  (baseUrl: string | undefined) =>
  (config: AxiosRequestConfig<unknown>): AxiosRequestConfig<unknown> => {
    const { baseURL: suppliedBaseUrl, ...untouchedConfig } = config;

    const baseURL = baseUrl?.trim().length ? baseUrl.trim() : suppliedBaseUrl;

    return {
      ...untouchedConfig,
      baseURL,
    };
  };

const abortSignalInterceptor = (
  config: AxiosRequestConfig<unknown>,
): AxiosRequestConfig<unknown> => {
  const {
    params = {},
    cancelToken: suppliedCancelToken,
    ...untouchedConfig
  } = config;

  const { abortSignal, ...untouchedParams } = params;

  let cancelToken = suppliedCancelToken;
  let signal;
  if (abortSignal instanceof AbortController) {
    cancelToken = undefined;
    signal = abortSignal.signal;
  }

  return {
    ...untouchedConfig,
    params: untouchedParams,
    cancelToken,
    signal,
  };
};

/**
 * Remove keys that are specific to
 * how this code works so that they
 * does not make it into the
 * query string of the url
 */
const removeConfigSpecifyKeysFromParamsInterceptor = (
  config: AxiosRequestConfig<unknown>,
): AxiosRequestConfig<unknown> => {
  const { params: suppliedParams = {}, ...untouchedConfig } = config;
  const params = { ...suppliedParams };

  delete params.action;
  delete params.serverType;

  return {
    ...untouchedConfig,
    params,
  };
};

export const chatAxios = axiosActual.create();
export default chatAxios;

/**
 * !!!!! NOTE !!!!!
 * Because of how Axios applies interceptors to
 * requests (First in Last Out) this order needs
 * to be in "reverse" to how one might
 * think (First in First Out) it needs
 * to be applied
 *
 * Once https://github.com/axios/axios/issues/1663 has
 * been resolved and we upgrade to the version
 * where it was fixed we will need to revisit this.
 *
 * --- Or ---
 *
 * Remove this when we upgrade to node 18+ and move to using the out of the box `fetch` function
 *
 */
chatAxios.interceptors.request.use(
  removeConfigSpecifyKeysFromParamsInterceptor,
);
chatAxios.interceptors.request.use(replacePlaceholdersInterceptor);
chatAxios.interceptors.request.use(pathAndMethodInterceptor);
chatAxios.interceptors.request.use(abortSignalInterceptor);
/**
 * Translate the server error that
 * returns as a 200 response to the correct
 * response code and throw error if code is not a
 * in the 200's or 300's
 */
chatAxios.interceptors.response.use(
  async (response: AxiosResponse<APIResponseErrorAs200>) => {
    const message =
      response.data.metadata?.outcome?.message ?? response.statusText;
    const code = response.data.metadata?.outcome?.status ?? response.status;

    if (code < 200 || code >= 400) {
      throw new AxiosError(
        message,
        code.toString(),
        response.config,
        response.request,
        response,
      );
    }

    return response;
  },
);

let authorizationHeaderInterceptorId: number | undefined;
let baseUrlInterceptorId: number | undefined;

export const setAuthToken = (
  options: SetAuthorizationHeaderInterceptorProps,
) => {
  if (authorizationHeaderInterceptorId) {
    chatAxios.interceptors.request.eject(authorizationHeaderInterceptorId);
    authorizationHeaderInterceptorId = undefined;
  }

  authorizationHeaderInterceptorId = chatAxios.interceptors.request.use(
    setAuthorizationHeaderInterceptor(options),
  );
};

export const setBaseUrl = (baseUrl: string | undefined | null) => {
  if (baseUrlInterceptorId) {
    chatAxios.interceptors.request.eject(baseUrlInterceptorId);
    baseUrlInterceptorId = undefined;
  }
  if (typeof baseUrl === 'string' && baseUrl?.trim().length) {
    baseUrlInterceptorId = chatAxios.interceptors.request.use(
      setBaseUrlInterceptor(baseUrl),
    );
  }
};
