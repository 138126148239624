import isEqual from 'lodash/isEqual';
import {
  AdobeDataLayer,
  type PageEvent,
  type PageData,
} from '@cigna/shared/analytics/core';
import type {
  WebAnalyticsService,
  WebErrorEvent,
  WebPageActionEvent,
  WebPageViewEvent,
} from '../interfaces';
import type { WebAnalyticsConfiguration } from '../models';

const adobeDataLayer = new AdobeDataLayer();

const isValidPageViewEvent = (pageViewEvent: WebPageViewEvent) => {
  const currentDataLayer = adobeDataLayer.getAdobeDataLayer();
  const currentDataLayerSize = currentDataLayer.length;
  // if the currentData has no events in it, then this event is valid
  if (currentDataLayerSize === 0) {
    return true;
  }
  // check to make sure lastEvent in the data layer includes PageViewEvent
  const lastEvent: PageEvent = currentDataLayer[0];
  const lastPageDataEvent = 'pageData' in lastEvent ? lastEvent.pageData : {};

  // if the pageData is the same, do not add
  return !isEqual(lastPageDataEvent, pageViewEvent.pageData);
};

class ReactAnalyticsService implements WebAnalyticsService {
  private config: WebAnalyticsConfiguration;

  constructor(config: WebAnalyticsConfiguration) {
    this.config = config;
  }

  trackPageView(pageViewEvent: WebPageViewEvent): void {
    if (!this.config.trackingDisabled && isValidPageViewEvent(pageViewEvent)) {
      adobeDataLayer.addEvent(pageViewEvent);
    }
  }

  trackPageAction(pageAction: WebPageActionEvent): void {
    if (!this.config.trackingDisabled) {
      adobeDataLayer.addEvent(pageAction);
    }
  }

  trackErrorAction(errorEvent: WebErrorEvent): void {
    if (!this.config.trackingDisabled) {
      adobeDataLayer.addEvent(errorEvent);
    }
  }

  // Public getter methods to access the private properties
  public getConfig(): WebAnalyticsConfiguration {
    return this.config;
  }

  public resetDataLayer(): void {
    adobeDataLayer.resetAdobeDataLayer();
  }
  public getCurrentPageData(): PageData | undefined {
    const currentDataLayer = adobeDataLayer.getAdobeDataLayer();
    const currentDataLayerSize = currentDataLayer.length;
    // if the currentData has no events in it, then this event is valid
    if (currentDataLayerSize === 0) {
      return;
    }

    const lastEvent: PageEvent = currentDataLayer[0];
    if ('pageData' in lastEvent) {
      return lastEvent.pageData;
    }
  }
}

export default ReactAnalyticsService;
