import type { ServerClassification } from '../../__model__/enum/server-classification';
import getData from '../../util/get-data';

export interface SelectCtaOptionProps {
  conversationId: string;
  messageId: string;
  optionId: string;
  optionValue: string;
  serverType: ServerClassification;
}

export const selectCtaOption = (props: SelectCtaOptionProps) =>
  getData({
    data: {
      _id: props.optionId,
      params: {},
      sourceMessageId: props.messageId,
      value: props.optionValue,
    },
    params: {
      action: 'sendCta',
      conversationId: props.conversationId,
      serverType: props.serverType,
    },
  });
