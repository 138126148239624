export enum ClaimStatus {
  InProgress = 'In Progress',
  Processed = 'Processed',
  Pended = 'Pended',
  Denied = 'Denied',
  Paid = 'Paid',
}

// todo: possible claim status values?
// "count": [
//   {"numclaims": 43, "claimStatus": "Pended"},
//   {"numclaims": 0, "claimStatus": "Denied"},
//   {"numclaims": 0, "claimStatus": "RFAI"},
//   {"numclaims": 0, "claimStatus": "Approved"},
//   {"numclaims": 4, "claimStatus": "Accepted"},
//   {"numclaims": 0, "claimStatus": "Processed"},
//   {"numclaims": 0, "claimStatus": "Referred"},
//   {"numclaims": 0, "claimStatus": "Adjusted"},
//   {"numclaims": 0, "claimStatus": "Reversal"},
//   {"numclaims": 0, "claimStatus": "Finalised"},
//   {"numclaims": 0, "claimStatus": "Referrel"}
// ]
// }
