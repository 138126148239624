import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// todo: figure out proper way to import from '@cigna/ui'
// eslint-disable-next-line @nx/enforce-module-boundaries
import EvernorthLogo from '../../../../../../shared/angular/assets-ui/images/evn_logo_hs_r_rgb_tempermint.svg';

import MainNavigation from '../main-navigation/main-navigation';
import UtilNavigation from '../util-navigation/util-navigation';
import styles from './global-header.module.scss';

export interface GlobalHeaderProps {
  isAuthenticated: boolean;
  userName?: string;
}

export function GlobalHeader(props: GlobalHeaderProps) {
  const { t } = useTranslation();
  return (
    <header className={styles.container}>
      <div className="enac-g-page-wrap">
        <div className={styles.headerInner}>
          <Link
            to={props.isAuthenticated ? '/dashboard' : '/'}
            className={styles.logoLink}
            data-test-id="logo-link"
          >
            <img
              src={EvernorthLogo}
              alt="Evernorth Health Services"
              width={'181'}
              height={'32'}
            />
          </Link>
          {props.isAuthenticated && (
            <div className={styles.accountLink}>
              <UtilNavigation />
            </div>
          )}
        </div>
      </div>
      {props.isAuthenticated && <MainNavigation />}
    </header>
  );
}

export default GlobalHeader;
