// Importing types that will not be in the final build.  These types
// are only exported from lodash and there is no other way to get them.
// eslint-disable-next-line  no-restricted-imports
import type { MapCache, MemoizedFunction } from 'lodash';
import memoize from 'lodash/memoize';
import type { APIResponseConversations } from '../../__model__/api/response/conversation.model';
import { ServerClassification } from '../../__model__/enum/server-classification';
import type { ChatContext } from '../../__model__/public-chat.model';
import Conversation from '../Conversation';
import { getActiveChat } from './get-active-chat';
import { getConversation } from './get-conversation';
import { getNewConversation } from './get-new-conversation';

type GetConversationSignature = (
  arg0: Pick<Conversation, 'id' | 'serverType'>,
) => Promise<Conversation | undefined>;

type GetNewConversationSignature = () => Promise<Conversation | undefined>;

type GetActiveConversationSignature = () => Promise<Conversation | undefined>;

const NEW_SYM = '__new';
const ACTIVE_SYM = '__active';

// Save off memoize.Cache so it can be set back later
const memoizeCache = memoize.Cache;

// Make sure that the caching for memoize.Cache is using WeakMap for the next call to memoize
memoize.Cache = WeakMap;
export const fetcher = memoize((context: ChatContext) => {
  const transformDataToConversation = async (
    data?: APIResponseConversations,
  ): Promise<Conversation | undefined> =>
    data ? new Conversation(data, context) : undefined;

  const addTransferIdsToCache =
    (cache: MapCache, promise: Promise<Conversation | undefined>) =>
    async (conversation: Conversation | undefined) => {
      conversation?.transferIds.forEach((id) => cache.set(id, promise));
      return conversation;
    };

  const addConversationToCache =
    (cache: MapCache, promise: Promise<Conversation | undefined>) =>
    async (conversation: Conversation | undefined) => {
      if (conversation?.id) {
        cache.set(conversation.id, promise);
      }
      return conversation;
    };

  // Save off memoize.Cache so it can be set back later
  const _memoizeCache = memoize.Cache;

  // Make sure that the caching for memoize.Cache is using Map for the next few calls to memoize
  memoize.Cache = Map;
  const newConversation: GetNewConversationSignature & MemoizedFunction =
    memoize<GetNewConversationSignature>(
      () => {
        const cache = newConversation.cache;

        const conversationPromise: Promise<Conversation | undefined> =
          getNewConversation({ context }).then(transformDataToConversation);

        cache.set(ACTIVE_SYM, conversationPromise);

        return conversationPromise
          .then(addTransferIdsToCache(cache, conversationPromise))
          .then(addConversationToCache(cache, conversationPromise))
          .finally(() => {
            cache.delete(NEW_SYM);
            if (cache.get(ACTIVE_SYM) === conversationPromise) {
              cache.delete(ACTIVE_SYM);
            }
          });
      },
      () => NEW_SYM,
    );

  const activeConversation: GetActiveConversationSignature & MemoizedFunction =
    memoize<GetActiveConversationSignature>(
      (): Promise<Conversation | undefined> => {
        const cache = activeConversation.cache;
        const conversationPromise = getActiveChat()
          .then(transformDataToConversation)
          .finally(() => {
            context.lastUserEventCallTime = Date.now();
            cache.delete(ACTIVE_SYM);
          });

        return conversationPromise
          .then(addTransferIdsToCache(cache, conversationPromise))
          .then(addConversationToCache(cache, conversationPromise));
      },
      () => ACTIVE_SYM,
    );

  const conversation: GetConversationSignature & MemoizedFunction =
    memoize<GetConversationSignature>(
      (props) => {
        const cache = conversation.cache;
        const { id: conversationId, serverType = ServerClassification.live } =
          props;

        const conversationPromise = getConversation({
          conversationId,
          followTransfer: true,
          serverType,
          context,
        }).then(transformDataToConversation);

        conversationPromise
          .then(addTransferIdsToCache(cache, conversationPromise))
          .then(addConversationToCache(cache, conversationPromise))
          .catch(() => {
            cache.delete(`${conversationId}`);
          });

        return conversationPromise;
      },
      (props) => props.id,
    );

  // Reset the value of memoize.Cache so it is does not break other locations
  memoize.Cache = _memoizeCache;

  newConversation.cache = conversation.cache;
  activeConversation.cache = conversation.cache;

  return {
    newConversation,
    activeConversation,
    conversation,
  };
});

// Reset the value of memoize.Cache so it is does not break other locations
memoize.Cache = memoizeCache;
