import React from 'react';
import { LeafHeading, LeafIconLegacy } from '@esi/leaf-react';
import styles from './empty-state.module.scss';
import noResultsFoundImage from './no-results-found.svg';

export interface EmptyStateProps {
  heading?: string;
  text?: string;
  child?: React.ReactElement;
  type?: 'noDataPlaceholder';
  showBorder?: boolean;
  iconName?: string;
}

export const EmptyState = (props: EmptyStateProps) => {
  if (props.type === 'noDataPlaceholder') {
    return (
      <div
        className={`${styles.noDataContainer} ${
          props.showBorder ? styles.emptyState : ''
        }`}
        data-test-id="no-result-found"
      >
        <LeafIconLegacy
          name={props.iconName ? props.iconName : 'x-hk-file-text'}
          className={styles.fileIcon}
        ></LeafIconLegacy>
        <LeafHeading type="title-small" data-test-id="no-result-heading">
          {props.heading}
        </LeafHeading>
        {props.text && <div className={styles.subText}>{props.text}</div>}

        {props.child && <div className={styles.subText}>{props.child}</div>}
      </div>
    );
  }
  return (
    <div className={styles.noResultContainer} data-test-id="no-result-found">
      <img
        src={noResultsFoundImage}
        alt="No Results Found"
        width={'400'}
        height={'256'}
      />
      <div className={styles.textContainer}>
        {props.heading && (
          <LeafHeading
            tagVariant="h2"
            type="headline-default"
            brandColor
            data-test-id="no-result-heading"
          >
            {props.heading}
          </LeafHeading>
        )}

        {props.text && <div className={styles.subText}>{props.text}</div>}

        {props.child && <div className={styles.subText}>{props.child}</div>}
      </div>
    </div>
  );
};

export default EmptyState;
