import type {
  EventEmitterInterface,
  MatchingKeys,
} from '../__model__/event-emmiter.model';

/**
 * Need to tempoarly disable this rule
 * because the preiter plugin (both the git hook
 * and the one run in the ide)
 * will take and move the '{' to the next line
 */
/* eslint-disable  @typescript-eslint/brace-style */
export class EventEmitter<
  Events extends Record<string | number | symbol, unknown[]>,
  Context = void,
> implements EventEmitterInterface<Events>
{
  /* eslint-enable  @typescript-eslint/brace-style */
  #subscription = new Map();
  #context: Context;

  constructor(context: Context) {
    this.#context = context;
  }

  off<
    P extends Exclude<keyof Events, MatchingKeys<Events, void, keyof Events>>,
  >(
    event: P | P[],
    listener:
      | ((context: Context, ...args: Events[P]) => void)
      | Array<
          (
            context: Context,
            ...args: Events[P]
          ) => void | ((context: Context) => void) | (() => void)
        >
      | ((context: Context) => void)
      | (() => void),
  ): this;
  off<P extends keyof Events>(
    event: P | P[],
    listener:
      | ((context: Context, ...args: Events[P]) => void)
      | Array<
          (
            context: Context,
            ...args: Events[P]
          ) => void | ((context: Context) => void) | (() => void)
        >
      | ((context: Context) => void)
      | (() => void),
  ): this {
    const _listener = listener instanceof Array ? listener : [listener];
    const _event = event instanceof Array ? event : [event];

    _event.forEach((eventName) => {
      const subscriptionSet = this.#subscription.get(eventName) ?? new Set();
      _listener.forEach((fn) => subscriptionSet.delete(fn));
      if (subscriptionSet.size) {
        this.#subscription.set(eventName, subscriptionSet);
      } else {
        this.#subscription.delete(eventName);
      }
    });

    return this;
  }

  on<P extends Exclude<keyof Events, MatchingKeys<Events, void, keyof Events>>>(
    event: P | P[],
    listener:
      | (() => void)
      | ((context: Context) => void)
      | ((context: Context, ...args: Events[P]) => void)
      | Array<
          | (() => void)
          | ((context: Context) => void)
          | ((context: Context, ...args: Events[P]) => void)
        >,
  ): this;
  on<P extends keyof Events>(
    event: P | P[],
    listener:
      | (() => void)
      | ((context: Context) => void)
      | ((context: Context, ...args: Events[P]) => void)
      | Array<
          | (() => void)
          | ((context: Context) => void)
          | ((context: Context, ...args: Events[P]) => void)
        >,
  ): this {
    const _events = event instanceof Array ? event : [event];
    const _listener = listener instanceof Array ? listener : [listener];

    _events.forEach((e) => {
      const subscriptionSet = this.#subscription.get(e) ?? new Set();
      _listener.forEach((fn) => subscriptionSet.add(fn));
      this.#subscription.set(e, subscriptionSet);
    });

    return this;
  }

  trigger<
    P extends Exclude<keyof Events, MatchingKeys<Events, void, keyof Events>>,
  >(event: P, ...args: Events[P]): this;
  trigger<P extends keyof Events>(event: P, ...args: unknown[]): this {
    this.selfTrigger(event, ...args);
    return this;
  }

  triggerConditionally<P extends keyof Events>(
    condition: boolean | (() => boolean),
    event: P,
    ...args: Events[P]
  ): this {
    const hasConditionBeenMeet =
      typeof condition === 'function' ? condition() : condition;

    if (hasConditionBeenMeet) {
      this.selfTrigger(event, ...args);
    }

    return this;
  }

  private selfTrigger(event: keyof Events, ...args: unknown[]) {
    const subscriptionSet = this.#subscription.get(event) ?? new Set();
    subscriptionSet.forEach(
      (fn: (context: Context | undefined, ...args: unknown[]) => void) =>
        fn(this.#context, ...args),
    );
  }
}
