import { createContext } from 'react';
import { ChatBubbleVariant } from '../__model__/enum/chat-bubble-variant.enum';
import { ServerClassification } from '../__model__/enum/server-classification';
import type { PublicSnapshot } from '../__model__/public-chat.model';

export const OmniContext = createContext<PublicSnapshot>({
  conversation: {
    load() {
      return;
    },
    active: {
      id: undefined,
      isClosed: true,
      meta: {},
      serverType: ServerClassification.bot,
      unreadCount: 0,
    },
    current: {
      created: undefined,
      id: undefined,
      isClosed: undefined,
      isLoading: true,
      messages: undefined,
      onSend: undefined,
      onUserTyping: undefined,
      hasLoadingError: true,
      serverType: undefined,
      unreadCount: 0,
    },
  },
  logout: () => Promise.resolve(),
  omni: {
    chatBubbleVariant: ChatBubbleVariant.suppressed,
    messages: {},
    isTerminated: false,
  },
});
export default OmniContext;
