import { createi18nInstance } from '@cigna/shared/react/i18n-util';
import { environment } from '../environments/environment';
import translations from './translations.json';

const i18nInstance = createi18nInstance(
  'enac-common',
  translations,
  environment.debug,
);

export default i18nInstance;
