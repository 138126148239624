import type { APIRequestNewConversation } from '../../__model__/api/request/new-conversation.model';
import type { APIResponseConversations } from '../../__model__/api/response/conversation.model';
import { ServerClassification } from '../../__model__/enum/server-classification';
import getData from '../../util/get-data';
import {
  type GetConversationWrapperProps,
  getConversationWrapper,
} from './get-conversation-wrapper';

export type GetNewConversationProps = Omit<
  GetConversationWrapperProps,
  'requestHandler' | 'conversationId' | 'serverType'
>;

export async function getNewConversation(
  props: GetNewConversationProps,
): ReturnType<typeof getConversationWrapper> {
  const { deviceType, fcmToken = deviceType } = props.context;

  return getConversationWrapper({
    ...props,
    followTransfer: true,
    requestHandler: ({ abortController }) =>
      getData<APIResponseConversations, APIRequestNewConversation>({
        abortController,
        params: {
          action: 'startConversation',
          serverType: ServerClassification.bot,
        },
        data: {
          client: {
            fcmToken,
            type: deviceType,
          },
          context: {
            tags: ['advocate', 'healthkit'],
          },
          type: ServerClassification.bot,
        },
      }),
  });
}
