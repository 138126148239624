import type { APIResponseConversationsMessageEvent } from '../../__model__/api/response/conversation.model';
import type { ServerClassification } from '../../__model__/enum/server-classification';
import getData from '../../util/get-data';

export interface SendMessageProps {
  conversationId: string;
  serverType: ServerClassification;
  text: string;
}

export const sendMessage = (props: SendMessageProps) =>
  getData<APIResponseConversationsMessageEvent>({
    data: {
      message: props.text,
    },
    params: {
      action: 'sendMessage',
      conversationId: props.conversationId,
      serverType: props.serverType,
    },
  });
