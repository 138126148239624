type EventHandler = (...args: unknown[]) => unknown;

interface Poller {
  setTime(ms: number): this;
  start(): this;
  stop(): this;
}

export const poller = (fn: EventHandler, suppliedTimeout = -1): Poller => {
  let timeout = suppliedTimeout;
  let intervalId: ReturnType<typeof setInterval> | undefined;
  return {
    setTime(ms: number) {
      timeout = ms;
      return this;
    },
    start() {
      if (intervalId || timeout < 1 || typeof fn !== 'function') {
        return this;
      }
      const initalTimeout = timeout;
      intervalId = setInterval(() => {
        if (initalTimeout === timeout) {
          fn();
          return;
        }

        this.stop();
        if (timeout > 0) {
          fn();
          this.start();
        }
      }, timeout);
      return this;
    },
    stop() {
      clearInterval(intervalId);
      intervalId = undefined;
      return this;
    },
  };
};
