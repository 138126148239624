import type {
  ClaimAttachmentType,
  FileMetaData,
  StandardApiResponse,
} from '@cigna/enac-provider-web/shared/util';
import { useMutation } from '@cigna/shared/react/react-query-web-data-access';

export interface ClaimAttachmentsSubmitRequestBody {
  serviceReferenceNumber: string;
  attachmentType: ClaimAttachmentType;
  rfaiCtrlNum?: string;
  patientControlNumber?: string;
  patientId?: string;
  claimId?: string;
  serviceBeginDate?: string;
  serviceEndDate?: string;
  rndrProvNpi?: string;
  billgProvTin?: string;
  email?: string;
  submittedBy: string;
  files: FileMetaData[];
}

export const usePostClaimAttachmentsSubmitApi = () =>
  useMutation<StandardApiResponse, ClaimAttachmentsSubmitRequestBody>({
    method: 'POST',
    url: `/claims/attachments/v1/pended/submit`,
  });
