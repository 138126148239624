import type { StandardApiResponse } from '@cigna/enac-provider-web/shared/util';
import { useQuery } from '@cigna/shared/react/react-query-web-data-access';

export interface GetClaimsCountResponse extends StandardApiResponse {
  claimsCount?: ClaimsCount[];
}
interface ClaimsCount {
  claimStatus: string;
  totalClaims: number;
}

export interface GetClaimsCountRequest {
  emailAddress?: string;
}

export const useGetClaimsCountApi = () =>
  useQuery<GetClaimsCountResponse>(
    {
      url: `/claims/v1/dashboard/count`,
      method: 'GET',
    },
    {
      keepPreviousData: true,
    },
  );
