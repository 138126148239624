export enum EventEmmiterEventName {
  ALL = '*',
  CONVERSATION_CHANGE_ACTIVE = 'conversation:changed:active',
  CONVERSATION_CHANGE_CURRENT = 'conversation:changed:current',
  CONVERSATION_CHECK_ID_ACTIVE = 'convesation:checkShouldBeActive',
  CONVERSATION_LOAD = 'conversation:load',
  CONVERSATION_SET_ACTIVE = 'conversation:set:active',
  CONVERSATION_SET_CURRRENT = 'conversation:set:current',
  CONVERSATION_UPDATE_ACTIVE = 'conversation:update:active',
  CONVERSATION_UPDATE_CURRENT = 'conversation:update:current',
  CONVERSATION_UPDATED = 'updated',
  LOGOUT = 'user:logout',
  LOGGED_OUT = 'user:loggedOut',
  OMNI_AVAIALABLITY_CHANGE = 'omni:avaiablity:change',
  OMNI_AVAIALABLITY_LOADED = 'omni:avaiablity:loaded',
  OMNI_LOADED = 'omni:loaded',
  OMNI_START = 'omni:start',
  OMNI_STOP = 'omni:stop',
  OMNI_UPDATE_CHAT_BUBBLE_VARIANT = 'omni:update:chatBubbleVariant',
  POLLING_AVAIALABLITY_START = 'polling:avaiablity:start',
  POLLING_AVAIALABLITY_STOP = 'polling:avaiablity:stop',
  POLLING_USEREVENT_START = 'polling:userevent:start',
  POLLING_USEREVENT_STOP = 'polling:userevent:stop',
}
