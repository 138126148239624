import type {
  AttachmentResponse,
  StandardApiResponse,
} from '@cigna/enac-provider-web/shared/util';
import { useMutation } from '@cigna/shared/react/react-query-web-data-access';

export interface UnsolicitedClaimAttachmentsRequestBody {
  name: string; // {serviceRef}/{fileName}
}

export interface UnsolicitedClaimAttachmentsResponse
  extends StandardApiResponse {
  result: AttachmentResponse;
}

export const usePostUnsolicitedClaimAttachmentsUploadApi = () =>
  useMutation<
    UnsolicitedClaimAttachmentsResponse,
    UnsolicitedClaimAttachmentsRequestBody
  >({
    method: 'POST',
    url: `/upload/claims/unsolicited/signedurl`,
  });
