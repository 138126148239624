import type { EnvironmentConfig } from './environment-config';

class EnvironmentService {
  private environment?: EnvironmentConfig;

  setEnvironment(env: EnvironmentConfig) {
    this.environment = env;
  }

  getEnvironment() {
    if (!this.environment) {
      // eslint-disable-next-line no-console
      console.log('Environment has not been set!');
    }
    return this.environment;
  }
}

export const environmentService = new EnvironmentService();
