import { LeafButton, LeafInlineAlert } from '@esi/leaf-react';
import type { ApiResponseMetadata } from '@cigna/enac-provider-web/shared/util';
import styles from './api-error-message.module.scss';

export interface ApiErrorMessageProps {
  metadata: ApiResponseMetadata;
  onRetry?: () => void;
}

export function ApiErrorMessage(props: ApiErrorMessageProps) {
  const handleRetry = () => {
    if (props.onRetry) {
      props.onRetry();
    }
  };
  return (
    <div className={styles.container}>
      <LeafInlineAlert status="error" iconName="error-alt">
        <strong data-test-id="api-error-code">
          {props.metadata.outcome.status}
        </strong>
        {' - '}
        <span data-test-id="api-error-msg">
          {props.metadata.outcome.message}
        </span>
      </LeafInlineAlert>
      {props.onRetry && (
        <div className="enac-g-margin-top-32">
          <LeafButton variant="secondary" text="Retry" onClick={handleRetry} />
        </div>
      )}
    </div>
  );
}

export default ApiErrorMessage;
