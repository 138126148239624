import { lazy, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Routes, useLocation, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import {
  RouteAuthGuard,
  RouteEntitlementGuard,
} from '@cigna/enac-provider-web/shared/ui';
import { useLoginTrackApi } from './api/use-login-track-api';
import GlobalFooter from './components/global-footer/global-footer';
import GlobalHeader from './components/global-header/global-header';

import i18n from './i18n';
import ForgotPassword from './pages/forgot-password/forgot-password';
import Impersonate from './pages/impersonate/impersonate';
import NotFound from './pages/not-found/not-found';
import Welcome from './pages/welcome/welcome';

const Registration = lazy(
  () => import('@cigna/enac-provider-web/registration-feature'),
);
const Dashboard = lazy(
  () => import('@cigna/enac-provider-web/dashboard-feature'),
);
const Account = lazy(() => import('@cigna/enac-provider-web/account-feature'));
const Attachments = lazy(
  () => import('@cigna/enac-provider-web/attachments-feature'),
);
const Directory = lazy(
  () => import('@cigna/enac-provider-web/directory-feature'),
);
const Claims = lazy(() => import('@cigna/enac-provider-web/claims-feature'));
const Remittance = lazy(
  () => import('@cigna/enac-provider-web/payment-feature'),
);
const Support = lazy(() => import('@cigna/enac-provider-web/support-feature'));

const AccessManagement = lazy(
  () => import('@cigna/enac-provider-web/access-management-feature'),
);

export function EnacProviderWebShellFeature() {
  const { isAuthenticated, user } = useAuth0();
  const { pathname } = useLocation();
  const loginTrackApi = useLoginTrackApi();

  // updated_at timestamp is only updated once per login, even with browser refresh
  const userTimestamp = user?.updated_at;

  const sessionTimestamp = window.sessionStorage.getItem('lastLogin');

  if (userTimestamp && userTimestamp !== sessionTimestamp) {
    sessionStorage.setItem('lastLogin', userTimestamp);
    setTimeout(() => {
      loginTrackApi.mutate(null);
    }, 100);
  }

  return (
    <I18nextProvider i18n={i18n}>
      <div className="enac-g-main-page-container">
        <GlobalHeader
          isAuthenticated={
            isAuthenticated &&
            pathname !== '/' &&
            !pathname.startsWith('/public')
          }
        />

        <Routes>
          <Route path="/" element={<Welcome />}></Route>
          <Route
            path="/public/forgot-password/"
            element={<ForgotPassword />}
          ></Route>
          <Route path="/public/impersonate/" element={<Impersonate />}></Route>
          <Route
            path="/public/registration/*"
            element={
              <Suspense>
                <Registration />
              </Suspense>
            }
          />

          <Route
            path="/account/access-management/*"
            element={
              <Suspense>
                <RouteAuthGuard>
                  <RouteEntitlementGuard hasEntitlement="User Access">
                    <AccessManagement />
                  </RouteEntitlementGuard>
                </RouteAuthGuard>
              </Suspense>
            }
          />
          <Route
            path="/account/*"
            element={
              <Suspense>
                <RouteAuthGuard>
                  <Account />
                </RouteAuthGuard>
              </Suspense>
            }
          />
          <Route
            path="/attachments/*"
            element={
              <Suspense>
                <RouteAuthGuard>
                  <Attachments />
                </RouteAuthGuard>
              </Suspense>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Suspense>
                <RouteAuthGuard>
                  <Dashboard />
                </RouteAuthGuard>
              </Suspense>
            }
          />
          <Route
            path="/provider-search/*"
            element={
              <Suspense>
                <RouteAuthGuard>
                  <Directory />
                </RouteAuthGuard>
              </Suspense>
            }
          />
          <Route
            path="/claims/*"
            element={
              <Suspense>
                <RouteAuthGuard>
                  <RouteEntitlementGuard hasEntitlement="Claims">
                    <Claims />
                  </RouteEntitlementGuard>
                </RouteAuthGuard>
              </Suspense>
            }
          />
          <Route
            path="/payment/*"
            element={
              <Suspense>
                <RouteAuthGuard>
                  <RouteEntitlementGuard hasEntitlement="Claims">
                    <Remittance />
                  </RouteEntitlementGuard>
                </RouteAuthGuard>
              </Suspense>
            }
          />
          <Route
            path="/support/*"
            element={
              <Suspense>
                <RouteAuthGuard>
                  <Support />
                </RouteAuthGuard>
              </Suspense>
            }
          />
          <Route
            path="*"
            element={<NotFound isAuthenticated={isAuthenticated} />}
          />
        </Routes>
        <GlobalFooter
          isAuthenticated={
            isAuthenticated &&
            pathname !== '/' &&
            !pathname.startsWith('/public')
          }
        />
      </div>
    </I18nextProvider>
  );
}

export default EnacProviderWebShellFeature;
