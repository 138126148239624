import { LeafBadge } from '@esi/leaf-react';
import { ClaimStatus } from '@cigna/enac-provider-web/shared/util';
export interface ClaimStatusBadgeProps {
  status: string;
}

export function ClaimStatusBadge(props: ClaimStatusBadgeProps) {
  const claimStatus = props.status.toLowerCase();

  let badgeStatus = 'neutral';
  let badgeVariant = 'light';

  if (claimStatus === ClaimStatus.Processed.toLowerCase()) {
    badgeStatus = 'attention';
  } else if (claimStatus === ClaimStatus.Paid.toLowerCase()) {
    badgeStatus = 'success';
  } else if (claimStatus === ClaimStatus.InProgress.toLowerCase()) {
    badgeStatus = 'neutral';
  } else if (claimStatus === ClaimStatus.Pended.toLowerCase()) {
    badgeStatus = 'warning';
  } else if (claimStatus === ClaimStatus.Denied.toLowerCase()) {
    badgeStatus = 'error';
  } else if (claimStatus === 'processed through cigna') {
    badgeStatus = 'neutral';
    badgeVariant = 'dark';
  }

  return (
    <LeafBadge
      text={props.status}
      status={badgeStatus}
      variant={badgeVariant}
    ></LeafBadge>
  );
}

export default ClaimStatusBadge;
