import { useCallback } from 'react';
import merge from 'lodash/merge';
import { useAnalytics } from '../context/AnalyticsProvider';
import type { WebPageViewEvent } from '../interfaces';
import type { AdditionalDataType } from '../models';
import { useAutoEventConfig } from './useAutoEventConfig';

const useTrackPageView = () => {
  const analyticsService = useAnalytics();
  const getAutoEventConfig = useAutoEventConfig();

  return useCallback(
    (pageViewEvent?: WebPageViewEvent, additionalData?: AdditionalDataType) => {
      const autoEventData = getAutoEventConfig('PageView');

      const event: WebPageViewEvent = merge(autoEventData, pageViewEvent, {
        event: 'pageLoad',
      });

      if (additionalData?.vendorName) {
        event.vendorName = additionalData.vendorName;
      }

      analyticsService?.trackPageView(event);
    },
    [analyticsService, getAutoEventConfig],
  );
};

export { useTrackPageView };
