import { useCallback } from 'react';
import type { HitType } from '@cigna/shared/analytics/core';
import {
  getPageInfo,
  useAnalytics,
  useUserInfo,
} from '../context/AnalyticsProvider';
import type { EventConfigData } from '../models/config.model';
import type { SiteTrackingInfoWeb } from '../models/data.model';

export const useAutoEventConfig = () => {
  const analyticsService = useAnalytics();
  const { userInfo, isAuthenticated } = useUserInfo();

  if (!analyticsService) {
    throw new Error(
      'Error: `useAutoEventConfig` must be used inside an AnalyticsProvider',
    );
  }

  return useCallback(
    (hitType: HitType, eventConfig?: EventConfigData) => {
      const config = analyticsService.getConfig();
      const pageData = getPageInfo(config);
      const additionalUserInfo = config.additionalUserInfo || {};

      // Get directly from dataLayer
      const previousPageData = analyticsService.getCurrentPageData();

      const siteTrackingInfo: SiteTrackingInfoWeb = {
        channel: 'Web',
        previousPageName: previousPageData?.pageName ?? '',
        externalReferringURL: window.document.referrer,
        internalReferringURL: previousPageData?.pageURL || '',
        hitType,
      };

      return {
        pageData,
        ...(isAuthenticated
          ? { userInfo: { ...userInfo, ...additionalUserInfo } }
          : {}),
        siteTrackingInfo,
        ...eventConfig,
      };
    },
    [analyticsService, isAuthenticated, userInfo],
  );
};
