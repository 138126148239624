import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LeafButton, LeafCard, LeafHeading } from '@esi/leaf-react';
import { PageWrapper } from '@cigna/enac-provider-web/shared/ui';

export function NotFound(props: { isAuthenticated?: boolean }) {
  const { t } = useTranslation();
  return (
    <PageWrapper pageTitle={t('notFound.heading')} variation="custom">
      <LeafHeading
        tagVariant="h1"
        type="title-xlarge"
        data-test-id="heading-main"
      >
        {t('notFound.heading')}
      </LeafHeading>
      <section className="enac-g-margin-top-64">
        <LeafCard>
          <p style={{ marginBottom: '2rem' }}>{t('notFound.body')}</p>
          {props.isAuthenticated ? (
            <Link to="/dashboard">
              <LeafButton
                variant="primary"
                text={t('notFound.dashboardLink')}
              />
            </Link>
          ) : (
            <Link to="/">
              <LeafButton variant="primary" text={t('notFound.loginLink')} />
            </Link>
          )}
        </LeafCard>
      </section>
    </PageWrapper>
  );
}

export default NotFound;
