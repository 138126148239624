import noop from 'lodash/noop';
import type { APIResponseConversations } from '../../__model__/api/response/conversation.model';
import { ServerClassification } from '../../__model__/enum/server-classification';
import type { ChatContext } from '../../__model__/public-chat.model';
import { getConversation } from './get-conversation';

type CombinedResultData = Pick<
  APIResponseConversations,
  'events' | 'participants' | 'participantNames'
> &
  Partial<Pick<APIResponseConversations, 'created'>>;

const followTransferConversation =
  (
    context: GetConversationWrapperProps['context'],
    abortController: GetConversationWrapperProps['abortController'],
  ) =>
  async (data: APIResponseConversations) =>
    Promise.allSettled(
      data.transferIds.map(async (id) =>
        getConversation({
          abortController,
          conversationId: id,
          serverType: ServerClassification.bot,
          followTransfer: false,
          context,
        }).then(async (transferData) => {
          if (transferData) {
            transferData.events = transferData.events.filter(
              ({ type }) => !['transfer', 'wait'].includes(type),
            );
          }
          return transferData;
        }),
      ),
    ).then(async (results): Promise<APIResponseConversations> => {
      const combinedResultData: CombinedResultData =
        results.reduce<CombinedResultData>(
          (acum: CombinedResultData, result): CombinedResultData =>
            result.status !== 'fulfilled'
              ? acum
              : {
                  ...acum,
                  created: acum.created ?? result.value?.created,
                  events: [...acum.events, ...(result.value?.events ?? [])],
                  participants: [
                    ...acum.participants,
                    ...(result.value?.participants ?? []),
                  ],
                  participantNames: {
                    ...acum.participantNames,
                    ...(result.value?.participantNames ?? {}),
                  },
                },
          {
            events: [],
            participants: [],
            participantNames: {},
            created: undefined,
          },
        );

      return {
        ...data,
        created: combinedResultData.created ?? data.created,
        events: [...combinedResultData.events, ...data.events],
        participants: [
          ...data.participants,
          ...combinedResultData.participants,
        ],
        participantNames: {
          ...combinedResultData.participantNames,
          ...data.participantNames,
        },
      };
    });

export interface GetConversationWrapperProps {
  abortController?: AbortController;
  context: ChatContext;
  conversationId?: string;
  followTransfer?: boolean;
  requestHandler: (arg0: {
    abortController: AbortController;
  }) => Promise<APIResponseConversations>;
}

export const getConversationWrapper = (
  props: GetConversationWrapperProps,
): Promise<APIResponseConversations | undefined> => {
  const {
    requestHandler,
    abortController,
    context,
    conversationId,
    followTransfer = false,
  } = props;

  const _abortController =
    abortController instanceof AbortController
      ? abortController
      : new AbortController();

  const _conversationId = conversationId ? conversationId : '__new_request';

  const key = `${_conversationId}_${followTransfer}`;
  const oppsiteKey = `${_conversationId}_${!followTransfer}`;
  let active: Promise<APIResponseConversations>;

  if (context.activeConversationRequests.has(key)) {
    active = context.activeConversationRequests.get(
      key,
    ) as Promise<APIResponseConversations>;
  } else if (
    followTransfer &&
    context.activeConversationRequests.has(oppsiteKey)
  ) {
    const activeOppsiteKey = context.activeConversationRequests.get(
      oppsiteKey,
    ) as Promise<APIResponseConversations>;
    active = activeOppsiteKey.then(
      followTransferConversation(context, _abortController),
    );

    context.activeConversationRequests.set(key, active);
    active
      .finally(() => {
        context.activeConversationRequests.delete(key);
      })
      .catch(noop);
  } else if (
    !followTransfer &&
    context.activeConversationRequests.has(oppsiteKey)
  ) {
    active = context.activeConversationRequests.get(
      oppsiteKey,
    ) as Promise<APIResponseConversations>;
  } else {
    active = requestHandler({ abortController: _abortController }).then(
      async (data) =>
        data.transferIds.length && followTransfer
          ? followTransferConversation(context, _abortController)(data)
          : data,
    );

    context.activeConversationRequests.set(key, active);
    context.abortControlers.add(_abortController);
    active
      .finally(() => {
        context.abortControlers.delete(_abortController);
        context.activeConversationRequests.delete(key);
      })
      .catch(noop);
  }
  return active;
};
