import { useAuth0 } from '@auth0/auth0-react';
import { useEnvironment } from '@cigna/shared/react/environment-provider-util';
import { useLogout } from '@cigna/shared/react/universal-logout-data-access';
import {
  useInfiniteQuery as useBaseInfiniteQuery,
  useMutation as useBaseMutation,
  useQueries as useBaseQueries,
  useQuery as useBaseQuery,
  type UseInfiniteQueryOptions,
  type UseMutationOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios';
import { isAuth0Error } from './utils';

interface AdditionalDetails {
  severity: string;
  code?: number;
  message?: string;
}
interface ResponseMetadata {
  metadata?: {
    serviceReferenceId: string;
    outcome?: {
      status: number;
      type: string;
      message?: string;
      code: number;
      additionalDetails?: Array<AdditionalDetails | undefined>;
    };
  };

  [key: string]: unknown;
}

export type ResponseError = AxiosError<ResponseMetadata> & Error;
export interface QueryFunctionData<TData>
  extends Omit<AxiosRequestConfig, 'baseURL' | 'params'> {
  authenticated?: boolean;
  overrideRootUrl?: string;
  dataFilter?: (data: AxiosResponse) => TData;
  params?: ((pageParam: number) => unknown) | unknown;
  isPublicRequest?: boolean;
}

export function useQuery<TQueryFnData = unknown>(
  queryData: QueryFunctionData<TQueryFnData>,
  options: UseQueryOptions<TQueryFnData> = {},
) {
  const requestData = useRequestData(queryData);
  if (!options.queryKey) {
    options.queryKey = [queryData];
  }

  return useBaseQuery<TQueryFnData>({
    queryFn: async ({ signal }) => {
      const response = await axios({
        method: 'GET',
        signal,
        ...(await requestData?.[0]()),
      });
      return queryData.dataFilter
        ? queryData.dataFilter(response)
        : response.data;
    },
    retry: 1,
    retryDelay: (attemptIndex) => Math.min(1000 ** attemptIndex, 15000),
    ...options,
  });
}

export function useInfiniteQuery<TQueryFnData = unknown>(
  queryData: QueryFunctionData<TQueryFnData>,
  options: UseInfiniteQueryOptions<TQueryFnData> = {},
) {
  const requestData = useRequestData(queryData);
  if (!options.queryKey) {
    options.queryKey = [queryData];
  }

  return useBaseInfiniteQuery<TQueryFnData>({
    queryFn: async ({ pageParam = 0, signal }) => {
      const response = await axios({
        method: 'GET',
        signal,
        ...(await requestData?.[0](pageParam)),
      });
      return queryData.dataFilter
        ? queryData.dataFilter(response)
        : response.data;
    },
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 ** attemptIndex, 15000),
    ...options,
  });
}

interface UseQueriesOptions<TQueryFnData> {
  queries: Array<{
    queryData: QueryFunctionData<TQueryFnData>;
    options: UseQueryOptions<TQueryFnData>;
  }>;
}

export function useQueries<TQueryFnData = unknown>({
  queries,
}: UseQueriesOptions<TQueryFnData>) {
  const requestData = useRequestData(queries.map((query) => query.queryData));
  const queriesOptions = queries.map<UseQueryOptions<TQueryFnData>>(
    ({ queryData, options }, index) => {
      if (!options.queryKey) {
        options.queryKey = [queryData];
      }

      return {
        queryFn: async ({ signal }) => {
          const response = await axios({
            method: 'GET',
            signal,
            ...(await requestData?.[index]()),
          });
          return queryData.dataFilter
            ? queryData.dataFilter(response)
            : response.data;
        },
        retry: 3,
        retryDelay: (attemptIndex) => Math.min(1000 ** attemptIndex, 15000),
        ...options,
      };
    },
  );

  return useBaseQueries({ queries: queriesOptions });
}

export function useMutation<TData = unknown, TVariables = unknown>(
  queryData: QueryFunctionData<TData>,
  options?: UseMutationOptions<TData, Error, TVariables>,
) {
  const requestData = useRequestData(queryData);

  axios.interceptors.request.use((config) => {
    if (typeof config.data === 'object' && typeof config.url === 'string') {
      config.url = Object.entries(config.data).reduce((url, [key, value]) => {
        if (typeof value === 'string' && url.includes(`:${key}`)) {
          delete config.data[key];
          return url.replace(`:${key}`, value);
        }
        return url;
      }, config.url);
    }

    return config;
  });

  return useBaseMutation<TData, ResponseError, TVariables>({
    mutationFn: async (variables: any) => {
      const request = await requestData?.[0]();
      if (variables?.url && request) {
        request.url = variables?.url;
        delete variables?.url;
      }
      const response = await axios({
        method: 'POST',
        ...request,
        data: { ...request?.data, ...variables },
      });
      return queryData.dataFilter
        ? queryData.dataFilter(response)
        : response.data;
    },
    ...options,
  });
}

export const useRequestData = (
  requests: Array<QueryFunctionData<unknown>> | QueryFunctionData<unknown>,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const {
    getAccessTokenSilently: onGetAccessToken,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    isAuthenticated: authenticated,
    isLoading,
  } = useAuth0();
  const { environment } = useEnvironment();
  const { logout } = useLogout();

  const isMultiple = Array.isArray(requests);
  const requestsArray = isMultiple ? requests : [requests];

  let isAuthenticated = authenticated;

  if (isLoading) {
    const hasPublicRequest = requestsArray.some(
      (request) => request.isPublicRequest,
    );

    // return early if isLoading is true and there is no public request
    if (!hasPublicRequest) {
      return;
    }
  }

  return requestsArray.map((originalData) => async (pageParam?: number) => {
    const data = { ...originalData };
    if (data.authenticated !== undefined) {
      isAuthenticated = data.authenticated;
    }
    delete data.authenticated;

    const requestData: AxiosRequestConfig = data;
    if (data.overrideRootUrl) {
      requestData.baseURL = data.overrideRootUrl;
    } else {
      requestData.baseURL = environment.rootApiUrl;
    }

    requestData.headers = {
      'Content-Type': 'application/json',
      ...requestData.headers,
    };

    if (!data.isPublicRequest) {
      if (isAuthenticated) {
        try {
          const accessToken = await onGetAccessToken({
            authorizationParams: {
              audience: environment.auth0.audience,
              scope: environment.auth0.scope,
            },
          });
          if (accessToken) {
            requestData.headers.Authorization = `Bearer ${accessToken}`;
          }
        } catch (e: unknown) {
          const isAuth0SessionExpiredError =
            e instanceof Error &&
            isAuth0Error(e) &&
            (e.error_description === 'Login required' ||
              e.error_description === 'Consent required');

          if (isAuth0SessionExpiredError) {
            logout({
              logoutParams: {
                returnTo: environment.auth0.redirect_uri,
              },
            });
          }
        }
      } else {
        logout({
          logoutParams: {
            returnTo: environment.auth0.redirect_uri,
          },
        });
      }
    }
    // for infinite queries update the requests parameters using the provided pageParam if it exists
    if (pageParam !== undefined && typeof data.params === 'function') {
      requestData.params = data.params(pageParam);
    }
    return requestData;
  });
};
