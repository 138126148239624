import { useContext, createContext } from 'react';
import type { EnvironmentConfig } from './environment-config';

export interface EnvironmentContextProps<T> {
  environment: EnvironmentConfig<T>;
  setEnvironment: (newEnvironment: EnvironmentConfig) => void;
  appLogoPaths?: string;
  landingImagePaths?: string;
}

const EnvironmentContext = createContext<EnvironmentContextProps<unknown>>(
  {} as unknown as EnvironmentContextProps<unknown>,
);

/**
 *  Hook used to retrieve the app Environment config values.
 *
 *  **note** - optionally, you can pass a TS generic type argument for
 *  your App's unique environment config. This allows your app's config
 *  to extend the base `EnvironmentConfig`.
 *
 * **Example usage**:
 * ```
 * const { environment } = useEnvironment();
 *
 *  <LeafButton
 *    onClick={() => {
 *      logout({
 *        logoutParams: {
 *          returnTo: environment.auth0.redirect_uri,
 *        },
 *      });
 *    }}
 *  />
 * ```
 *
 * **Example usage with TS generic type extension**:
 * ```
 *  interface CustomEnvironmentConfig {
 *    name: string;
 *    hasRegistered: boolean;
 *  }
 *  ...
 *  const customEnvironment: EnvironmentConfig<CustomEnvironmentConfig> = {
 *    ...defaultEnvironment,
 *    name: 'foo bar',
 *    hasRegistered: true,
 *  };
 *  ...
 *  root.render(
 *    <StrictMode>
 *      <AppWrapper environment={customEnvironment}>
 *        <App />
 *      </AppWrapper>
 *    </StrictMode>,
 *  );
 * ...
 *  const { environment } = useEnvironment<CustomEnvironmentConfig>();
 *  ...
 *  <p>My name is {environment.name}</p>>
 * ```
 */

export const useEnvironment = <T extends {} = {}>() =>
  useContext(EnvironmentContext) as EnvironmentContextProps<T>;

export { EnvironmentContext };
export default EnvironmentContext;
