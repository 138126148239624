import { metadata200Mock } from '@cigna/enac-provider-web/test-util';
import { createMockService } from '@cigna/shared/mock-services-util';
import type { ContractItem } from '../src/lib/api/use-get-contracts-api';

export const mockContracts: ContractItem[] = [
  {
    contractStatus: 'COMPLETED',
    contractUrl:
      'https://enac-dev-s3-ap0001-datastore.s3.amazonaws.com/s004p0001a/ECTS-20240312120443492S245%7C9a22360f-ecdc-43eb-8b47-588d23818d26/%5BDEMO%20USE%20ONLY%5D%20Participating%20Network%20Provider%20Contract_agreement.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=ASIAYCMNYEGO45MGINP2%2F20240320%2Fus-east-1%2Fs3%2Faws4_request\u0026X-Amz-Date=20240320T184502Z\u0026X-Amz-Expires=3600\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Security-Token=IQoJb3JpZ2luX2VjEAMaCXVzLWVhc3QtMSJHMEUCIQCH303xRk6cd%2FcoL1yJwakBJb3Y4Nd90QJyJIW2jLDYfwIgeWQyR5Hq0BWrIbPK88ohMBW4tccFMAQxXyoixXKIOScqiwMIHBABGgw1NTQ4ODQ5MzE5OTciDCx7BuWk0wYVeko57yroAiQKuxF8cN3y4ZAjPxvHU4VugeFcYBl36imJscwNXjTLxAVCFRtXfH6Dw%2BwpJ6YiW5h9C6ormMuRUYRlRNKcm1tsdOm3RIqNIQm3iBkT5lasJvlERy27XH3HlnZOSkgXR6p5vBuamRS1VitoGr2e%2B0a13XTzo6Qu3q8u4rvcnRpnY9YMne%2Bs1XHtyNXPHURHftF3Jz1gyBaq6RgjEl8iYOS5OQt40pIYh9GcC2Vs1yd%2BmCq71IdM8IvPFDaOA71Hrxp2z83Gks58%2BLS%2Bk3mxNJdOJmLol85GruMfH%2BZTOBBgwFZEKcIHJheOXAg%2BgISMRodJCN4oe6sftxitINzArVEnyRsEr%2B7CY10tz7cZjWQmKGCKt38wfvQ5Kd%2F%2BUSd8DGr4I1Kf82OH0pZIzlgMixovHfgpwDxVqPNgj8Xqd%2BjlC2mQADKxBGl1oX0m5BL9pB8rvx8Gph%2FamFEFXGO9BpLceTDEwBoJVTCr3eyvBjqdAbpXDMtH6voUejvcqBIlpkdqRrcbb4EMUOnuqzdWFz0XRoZ2I%2F81vixs0E2%2FwRaJI5tO7hb3KJb39yzbOFsX6TGrZN3dItYBeJ2jEnqF1dvg1Ecc6NyNOX2NywLCg1BGHLq%2B8ouuKCVbB%2FViBJaTFIHph5S0hOuIUZ6lrwvJRf1Of1mD4Ni%2B5yfIDJDvRhnCaRbus5SodCUh8NcA6XQ%3D\u0026X-Amz-Signature=e370cb0cbbb2ee573708965dee39e3bd01f5bb899a839e8258380212174d7f01',
    sentDate: '2024-03-12 17:05:37.474880',
    signedDate: '2024-03-15 20:17:37.657863',
    signedIndicator: 'Y',
    tin: '010613970',
    title: 'Participating Network Provider Contract',
  },
  {
    contractStatus: 'PENDING',
    contractUrl:
      'https://secure.na4.adobesign.com/public/apiesign?pid=CBFCIBAA3AAABLblqZhBvzz9XZjFGVP6RgNhYlrPoZk3QnWMsXL6eS5pB0L6bdjyVile7Hdp_TjEeZQOn5fKJCroagjT2C6qPtOFAl2bO\u0026client_id=CBJCHBCAABAA4xWbIeVwBW2vGquDFCYlXNW8PX8qGynj',
    sentDate: '2024-03-20 16:37:04.898050',
    signedDate: '',
    signedIndicator: 'N',
    tin: '760339933',
    title: 'Participating Network Provider Contract',
  },
];

const handlers = createMockService({
  method: 'get',
  url: '*/provider/v1/contracts/list',
  callback: (_, res, ctx) =>
    res(
      ctx.json({
        metadata: metadata200Mock,
        contractList: mockContracts,
      }),
    ),
});

export default handlers;
