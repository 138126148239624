import type { APIRequestIdentityOmnibus } from '../../__model__/api/request/omnibus-identy.modle';
import type {
  APIResopnseIdentyOmnibus,
  APIResopnseIdentyOmnibusLiveAvailabilityResponse,
  APIResopnseIdentyOmnibusPropertiesResponse,
} from '../../__model__/api/response/omnibus-idenity.model';
import { IdenityOmnibusSeq } from '../../__model__/enum/omnibus-idenity-seq.enum';
import { ServerClassification } from '../../__model__/enum/server-classification';

import getData from '../../util/get-data';

export interface OmniProcessdData {
  props?: APIResopnseIdentyOmnibusPropertiesResponse['success'];
  availability?: APIResopnseIdentyOmnibusLiveAvailabilityResponse['success'];
}

export const getOmnibus = async () =>
  getData<APIResopnseIdentyOmnibus, APIRequestIdentityOmnibus>({
    params: {
      serverType: ServerClassification.identity,
      action: 'omni',
    },
    data: [
      {
        seq: IdenityOmnibusSeq.props,
        type: 'v2/properties',
        payload: null,
      },
      {
        seq: IdenityOmnibusSeq.availability,
        type: 'v1/availability/live',
        payload: {
          offset: new Date().getTimezoneOffset().toString(),
          format: '12h',
        },
      },
    ],
  }).then(async (data) =>
    data.reduce((acum: OmniProcessdData, record) => {
      if (record.seq === IdenityOmnibusSeq.props) {
        acum.props =
          record.success as APIResopnseIdentyOmnibusPropertiesResponse['success'];
      } else if (record.seq === IdenityOmnibusSeq.availability) {
        acum.availability =
          record.success as APIResopnseIdentyOmnibusLiveAvailabilityResponse['success'];
      }

      return acum;
    }, {}),
  );
