import { Link } from 'react-router-dom';
import { LeafButton } from '@esi/leaf-react';
import { useGetUserProfileApi } from '@cigna/enac-provider-web/shared/data-access';
import { useTrackPageAction } from '@cigna/shared/react/analytics-util';

export function UtilNavigation() {
  const { data } = useGetUserProfileApi();
  const trackPageAction = useTrackPageAction();

  if (data?.identity) {
    return (
      <Link
        to={'/account'}
        onClick={() => {
          trackPageAction(
            {
              controlText: `${data.identity.firstName} ${data.identity.lastName}`,
              controlType: 'Click',
              controlName: 'top-profile-icon',
              controlRegion: 'header',
            },
            {
              reqData: {
                requirementID: 'ac-Das003',
                userStory: 'ACNBPT4-285',
              },
            },
          );
        }}
        data-test-id="btn-account-link"
      >
        <LeafButton
          data-test-id="btn-account"
          text={`${data.identity.firstName} ${data.identity.lastName}`}
          iconPosition="before"
          iconName="x-hk-user"
          variant="tertiary"
          size="sm"
        />
      </Link>
    );
  }
}

export default UtilNavigation;
