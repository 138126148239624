import { lazy } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { type AppState, Auth0Provider } from '@auth0/auth0-react';
import { EnacProviderWebShellFeature } from '@cigna/enac-provider-web/shell-feature';
import { AnalyticsProvider } from '@cigna/shared/react/analytics-util';
import { EnvironmentProvider } from '@cigna/shared/react/environment-provider-util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { QueryClientProviderWeb } from '@cigna/shared/react/react-query-web-data-access';

import { analyticsConfig } from '../config/analytics.config';
import { environment } from '../environments/environment';
import i18n from './i18n';

const DebugApp = lazy(() => import('../debug-app/debug-app'));

export function App() {
  const navigate = useNavigate();

  return (
    <EnvironmentProvider environment={environment}>
      <Auth0Provider
        domain={environment.auth0.domain}
        clientId={environment.auth0.clientId}
        authorizationParams={{
          redirect_uri: environment.auth0.redirect_uri,
          audience: environment.auth0.audience,
          scope: environment.auth0.scope,
          connection: environment.auth0.connection,
        }}
        onRedirectCallback={(appState?: AppState) =>
          navigate(appState?.returnTo || window.location.pathname)
        }
      >
        <QueryClientProviderWeb>
          <I18nextProvider i18n={i18n}>
            <AnalyticsProvider config={analyticsConfig}>
              {environment.debug ? (
                <DebugApp />
              ) : (
                <EnacProviderWebShellFeature />
              )}
            </AnalyticsProvider>
          </I18nextProvider>
        </QueryClientProviderWeb>
      </Auth0Provider>
    </EnvironmentProvider>
  );
}

export default App;
