export enum ClosedReasonCode {
  AGENT_CLOSED = 'AGENT_CLOSED',
  AGENT_IDLE_TIMEOUT = 'AGENT_IDLE_TIMEOUT',
  AGENT_LEFT_TIMEOUT = 'AGENT_LEFT_TIMEOUT',
  AGENT_OFFLINE_TIMEOUT = 'AGENT_OFFLINE_TIMEOUT',
  AGENT_ONLINE_TIMEOUT = 'AGENT_ONLINE_TIMEOUT',
  AWAITING_AGENT_TIMEOUT = 'AWAITING_AGENT_TIMEOUT',
  CLIENT_SIDE_TRANSFER_ERROR = 'CLIENT_SIDE_TRANSFER_ERROR',
  TRANSFER = 'TRANSFER',
  TRANSFER_ERROR = 'TRANSFER_ERROR',
  USER_CLOSED = 'USER_CLOSED',
  USER_TIME_OUT = 'USER_TIME_OUT',
}
