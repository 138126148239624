import type { StandardApiResponse } from '@cigna/enac-provider-web/shared/util';
import { useQuery } from '@cigna/shared/react/react-query-web-data-access';

export interface GetUserProfileResponse extends StandardApiResponse {
  identity: IdentityData;
}
export interface IdentityData {
  addressLine1: string;
  addressLine2: string;
  city: string;
  email: string;
  employeeId: string;
  firstName: string;
  idpUserId: string;
  lastName: string;
  lob: string;
  middleName: string;
  mobilePhone: string;
  phone: string;
  phoneExt: string;
  pwdLastChangedTS: string;
  state: string;
  userId: string;
  zip: string;
  attestations: string[];
  roles: UserRole[];
  entitlements: UserEntitlement[];
  tins: TinData[];
}

type UserRole =
  | 'Primary Admin'
  | 'Secondary Admin'
  | 'Back Office Billing Claims'
  | 'Back Office Precertification'
  | 'Front Office'
  | 'Contracting';

export type UserEntitlement =
  | 'Watch'
  | 'EFT'
  | 'User Access'
  | 'Practice Information'
  | 'Analytics & Reporting'
  | 'Prior Authorization'
  | 'Claims'
  | 'Eligibility and Benefits'
  | 'Referrals'
  | 'PCLU'
  | 'Documents';

interface TinEntitlement {
  name: string;
  functions: string[];
}
// TODO: Needs to update tin fields
export interface TinData {
  number: string;
  contracted: boolean;
  sanctioned: boolean;
  name: string;
  hasComrcl: boolean;
  hasGovrnm: boolean;
  lob: string;
  entitlements: TinEntitlement[];
}

export const useGetUserProfileApi = () =>
  useQuery<GetUserProfileResponse>(
    {
      url: `/identity/v1/profile/user`,
    },
    { staleTime: Infinity },
  );
