import type {
  AttachmentResponse,
  StandardApiResponse,
} from '@cigna/enac-provider-web/shared/util';
import { useMutation } from '@cigna/shared/react/react-query-web-data-access';

export interface SoliciatedClaimAttachmentsRequestBody {
  name: string; // {serviceRef}/{fileName}
}

export interface SoliciatedClaimAttachmentsResponse
  extends StandardApiResponse {
  result: AttachmentResponse;
}

export const usePostSoliciatedClaimAttachmentsUploadApi = () =>
  useMutation<
    SoliciatedClaimAttachmentsResponse,
    SoliciatedClaimAttachmentsRequestBody
  >({
    method: 'POST',
    url: `/upload/claims/solicited/signedurl`,
  });
