import type { APIResponseConversationsActive } from '../../__model__/api/response/active-conversation.model';
import type { APIResponseConversations } from '../../__model__/api/response/conversation.model';
import { ServerClassification } from '../../__model__/enum/server-classification';
import getData from '../../util/get-data';

export const getActiveChat = async () =>
  getData<APIResponseConversationsActive>({
    params: {
      serverType: ServerClassification.live,
      action: 'activeConversations',
    },
  })
    .then(async ({ nextConversation, prevConversation }) => {
      if (!nextConversation || !prevConversation) {
        return undefined;
      }
      const combinedConversation: APIResponseConversations = {
        ...nextConversation,
        created: prevConversation.created,
        events: [
          ...prevConversation.events.filter(
            ({ type }) => !['transfer', 'wait'].includes(type),
          ),
          ...nextConversation.events,
        ],
        participants: [
          ...prevConversation.participants,
          ...nextConversation.participants,
        ],
        participantNames: {
          ...prevConversation.participantNames,
          ...nextConversation.participantNames,
        },
      };
      return combinedConversation;
    })
    .catch(async () => undefined);
