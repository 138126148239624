import type { APIRequestIdentityOmnibusAvailabilityProps } from '../../__model__/api/request/omnibus-identy.modle';
import type { APIResponseLiveAvailabilityResponse } from '../../__model__/api/response/omnibus-idenity.model';
import { ServerClassification } from '../../__model__/enum/server-classification';
import { getData } from '../../util/get-data';

export const getAvailability = () =>
  getData<
    APIResponseLiveAvailabilityResponse,
    APIRequestIdentityOmnibusAvailabilityProps
  >({
    params: {
      action: 'availability',
      format: '12h',
      offset: new Date().getTimezoneOffset().toString(),
      serverType: ServerClassification.identity,
    },
  });
