import type { APIRequestTransferConversation } from '../../__model__/api/request/transfer.model';
import type { APIResponseConversationsTransfer } from '../../__model__/api/response/transfer.model';
import type { DeviceType } from '../../__model__/enum/device-type.enum';
import { ServerClassification } from '../../__model__/enum/server-classification';
import getData from '../../util/get-data';

export interface TransferConversationProps {
  converstionId: string;
  serverType: ServerClassification;
  deviceType: DeviceType;
  text: string;
}

export const transferConversastion = (props: TransferConversationProps) =>
  getData<APIResponseConversationsTransfer, APIRequestTransferConversation>({
    data: {
      client: {
        type: props.deviceType,
      },
      initial: props.text,
      type: ServerClassification.live,
    },
    params: {
      action: 'userTransfered',
      conversationId: props.converstionId,
      serverType: props.serverType,
    },
  });
