import { initReactI18next } from 'react-i18next';
import i18n, { type ResourceKey } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import ResourcesToBackend from 'i18next-resources-to-backend';
import { i18nRequest, i18nParseLoadPayload, i18nLoadPath } from './i18-backend';

/**
 *  Util function used to create new i18n instance for an app or lib using its own set of i18n translation keys.
 *
 * @param nameSpace - The namespace used for the translation. Should be a string.
 * @param translations - The actual translation keys and values for a specific language. It should be a JSON object with string keys.
 * @param debug - An optional param to enable debug logs in console. Should be boolean.
 *
 * Example usage:
 *
 * `/app/i18n.ts`:
 * ```
 * import { createi18nInstance } from '@cigna/shared/react/i18n-util';
 * import translations from './translations.json';
 *
 * const i18nInstance = createi18nInstance('translation', translations);
 *
 * export default i18nInstance;
 * ```
 *
 * `/app/app.tsx`:
 * ```
 * <I18nextProvider i18n={i18n}>
 *   ...other children in react tree
 * </I18nextProvider>
 * ```
 */

export const createi18nInstance = <T extends Record<string, ResourceKey>>(
  nameSpace: string,
  translations: T,
  debug: boolean = false,
) => {
  const i18nInstance = i18n.createInstance();

  i18nInstance
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug,
      returnNull: false,
      fallbackLng: 'en',
      supportedLngs: ['en', 'es'],
      ns: [nameSpace],
      defaultNS: nameSpace,
      lng: 'en',
      resources: {
        en: {
          [nameSpace]: translations,
        },
      },
    });

  return i18nInstance;
};

export const createi18nBackendInstance = <
  T extends Record<string, ResourceKey>,
>(
  nameSpace: string,
  manifestInstance: string,
  baseUrl: string,
  translations: T,
  debug: boolean = false,
) => {
  const namespaces = Object.keys(translations).filter(
    (ns) => ns !== '$comment',
  );
  const i18nInstance = i18n.createInstance();

  i18nInstance
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      debug,
      returnNull: false,
      initImmediate: false,
      lng: 'en-US',
      fallbackLng: 'en-US',
      defaultNS: nameSpace,
      ns: [...namespaces], // preload namespaces
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
      backend: {
        backends: [
          HttpBackend,
          ResourcesToBackend({
            en: {
              [nameSpace]: translations,
            },
          }),
        ],
        backendOptions: [
          {
            request: i18nRequest(translations),
            parseLoadPayload: i18nParseLoadPayload(translations),
            loadPath: i18nLoadPath(manifestInstance, baseUrl),
          },
        ],
      },
    });

  return i18nInstance;
};

export const createI18nFallbackInstance = () => {
  const i18nFallback = i18n.createInstance();
  i18nFallback.init();
  return i18nFallback;
};
