import { createPortal } from 'react-dom';

const headRoot = document.head;

interface HeadProps {
  children: Parameters<typeof createPortal>[0];
}

export const Head = ({ children }: HeadProps) =>
  createPortal(children, headRoot);
