import { type PropsWithChildren, useState, Fragment } from 'react';
import {
  LeafButton,
  LeafIconLegacy,
  LeafSkeletonLoader,
} from '@esi/leaf-react';
import {
  flexRender,
  type TableOptions,
  useReactTable,
  type SortingState,
  type ExpandedState,
  type PaginationState,
  getSortedRowModel,
  type OnChangeFn,
  type RowSelectionState,
  getExpandedRowModel,
  type Row,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { formatNumber } from '@cigna/enac-provider-web/shared/util';
import styles from './data-table.module.scss';

export function DataTable<T>(
  props: PropsWithChildren<{
    table: TableOptions<T>;
    testId: string;
    paginationState?: PaginationState;
    rowSelectionState?: RowSelectionState;
    sortingState?: SortingState;
    handlePagination?: OnChangeFn<PaginationState>;
    handleRowSelection?: OnChangeFn<RowSelectionState>;
    handleSorting?: OnChangeFn<SortingState>;
    renderExpandedRow?: (props: { row: Row<T> }) => React.ReactElement;
    renderFooter?: React.ReactElement;
    headerSize?: 'small' | 'normal';
    renderNoData?: React.ReactElement;
    isLoading?: boolean;
  }>,
) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    enableSorting: false,
    enableRowSelection: false,
    ...props.table,
    state: {
      sorting: props.sortingState || sorting,
      expanded,
      pagination: props.paginationState,
      rowSelection: props.rowSelectionState,
    },
    onExpandedChange: setExpanded,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onPaginationChange: props.handlePagination,
    onRowSelectionChange: props.handleRowSelection,
    onSortingChange: props.handleSorting,
  });

  return (
    <section>
      <div className={styles.tableWrapper}>
        {props.children && (
          <div className={styles.tableTopper}>{props.children}</div>
        )}
        <table
          className={classNames(
            { [styles.table]: true },
            { [styles.tableWithChildren]: props.children },
          )}
          data-test-id={props.testId}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className={
                      props.headerSize === 'small' ? styles.thSmall : undefined
                    }
                  >
                    <div
                      className={`${styles.thWrap} ${
                        header.column.getCanSort() ? styles.thSortable : ''
                      }`}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: (
                          <div>
                            <LeafIconLegacy
                              name="delta-up"
                              data-test-id="delta-up"
                            />
                            <LeafIconLegacy
                              name="keyboard-arrow-down"
                              data-test-id="arrow-down"
                            />
                          </div>
                        ),
                        desc: (
                          <div>
                            <LeafIconLegacy
                              name="keyboard-arrow-up"
                              data-test-id="arrow-up"
                            />
                            <LeafIconLegacy
                              name="delta-down"
                              data-test-id="delta-down"
                            />
                          </div>
                        ),
                      }[header.column.getIsSorted() as string] ?? null}
                      {header.column.getCanSort() &&
                      !header.column.getIsSorted() ? (
                        <div>
                          <LeafIconLegacy
                            name="keyboard-arrow-up"
                            data-test-id="arrow-up"
                          />
                          <LeafIconLegacy
                            name="keyboard-arrow-down"
                            data-test-id="arrow-down"
                          />
                        </div>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <Fragment key={`${index}-fragment`}>
                <tr key={index}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
                {row.getIsExpanded() && props.renderExpandedRow && (
                  <tr key={`${index}-expanded`}>
                    <td
                      key={`${index}-expanded-cell`}
                      className={styles.expandedRow}
                      colSpan={table.getAllLeafColumns().length}
                    >
                      {props.renderExpandedRow({ row })}
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
            {props.table.data.length === 0 && !props.isLoading && (
              <tr>
                <td colSpan={table.getAllFlatColumns().length}>
                  {props.renderNoData || (
                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                      No data found
                    </div>
                  )}
                </td>
              </tr>
            )}
            {props.isLoading &&
              props.table.data.length === 0 &&
              Array(props.paginationState?.pageSize || 5)
                .fill({})
                .map((el, index) => (
                  <Fragment key={`loading-fragment-${index}`}>
                    <tr key={`loading-row-${index}`}>
                      {Array(table.getAllFlatColumns().length)
                        .fill({})
                        .map((cell, i) => (
                          <td key={`loading-cell-${i}`}>
                            <LeafSkeletonLoader
                              height="16px"
                              width="100%"
                              variant="rectangle"
                            />
                          </td>
                        ))}
                    </tr>
                  </Fragment>
                ))}
          </tbody>
          {props.renderFooter && <tfoot>{props.renderFooter}</tfoot>}
        </table>
      </div>
      {props.table.manualPagination &&
        props.paginationState &&
        props.table.rowCount !== undefined &&
        props.table.rowCount > 0 && (
          <div className={styles.paginationContainer}>
            <div
              className={styles.pageCounts}
              data-test-id="pagination-page-counts"
            >
              <span data-test-id="pagination-start">
                {props.paginationState.pageIndex *
                  props.paginationState.pageSize +
                  1}
              </span>
              -
              <span data-test-id="pagination-end">
                {props.table.rowCount <=
                props.paginationState.pageIndex *
                  props.paginationState.pageSize +
                  props.paginationState.pageSize
                  ? props.table.rowCount
                  : props.paginationState.pageIndex *
                      props.paginationState.pageSize +
                    props.paginationState.pageSize}{' '}
              </span>
              of{' '}
              <span data-test-id="pagination-total">
                {formatNumber(props.table.rowCount)}
              </span>
            </div>
            <div className="enac-g-hide-for-print">
              <div className={styles.pageButtons}>
                <LeafButton
                  text="Previous"
                  iconPosition="before"
                  iconName="keyboard-arrow-left"
                  variant="tertiary"
                  size="sm"
                  disabled={!table.getCanPreviousPage()}
                  onClick={() => table.previousPage()}
                  data-test-id="pagination-btn-prev"
                ></LeafButton>
                <LeafButton
                  text="Next"
                  iconPosition="after"
                  iconName="keyboard-arrow-right"
                  variant="tertiary"
                  size="sm"
                  disabled={!table.getCanNextPage()}
                  onClick={() => table.nextPage()}
                  data-test-id="pagination-btn-next"
                ></LeafButton>
              </div>
            </div>
          </div>
        )}
    </section>
  );
}

export default DataTable;
