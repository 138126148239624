import { rest, type SetupWorker } from 'msw';
import { type SetupServer } from 'msw/node';
import { MockServiceHandler } from './models';

export const createMockService = (
  handlers: MockServiceHandler | MockServiceHandler[],
) => {
  if (Array.isArray(handlers)) {
    return handlers;
  }
  return [handlers];
};

export const attachMockService = (
  server: SetupServer | SetupWorker,
  handlers: MockServiceHandler[],
) => {
  const services = handlers
    .filter((item) => item)
    .map((handler) => rest[handler.method](handler.url, handler.callback));
  server.use(...services);
};
