export interface Auth0Error extends Error {
  error?: string;
  error_description?: string;
}

export interface NotFoundError extends Error {
  response?: ErrorResponse;
}

interface ErrorResponse {
  status?: number;
}

export const isAuth0Error = (error: Error): error is Auth0Error =>
  (error as Auth0Error).error_description !== undefined;

export const isNotFoundError = (error: unknown): error is NotFoundError =>
  Boolean(error) && (error as NotFoundError).response?.status === 404;
