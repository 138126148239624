import type { MessageType } from '../enum/message-type.enum';
import type { SystemMessageKey } from '../enum/system-message-key.enum';
import type { ViewableMessage } from '../message.model';

export const TRANSFER_ERROR_MSG_ID = 'TRANSFER_ERROR';

export interface SystemMessage extends ViewableMessage {
  type: MessageType.system;
  messageKey: SystemMessageKey;
}
