import { useState } from 'react';
import { LeafRadioField, LeafRadioFieldItem } from '@esi/leaf-react';
import FilterToggle from '../filter-toggle/filter-toggle';
import styles from './filter-panel-radio.module.scss';

export interface FilterPanelRadioProps {
  btnLabel: string;
  options: RadioOption[];
  choice: string;
  testId: string;
  onChange?: (value: string) => void;
}

export interface RadioOption {
  label: string;
  value: string;
  shortLabel?: string;
}

export function FilterPanelRadio(props: FilterPanelRadioProps) {
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const onChange = (val: string) => {
    setShowFilter(false);
    props.onChange && props.onChange(val);
  };

  const shortLabel = props.options.find(
    (o) => o.value === props.choice,
  )?.shortLabel;

  return (
    <FilterToggle
      label={`${props.btnLabel}${shortLabel ? `: ${shortLabel}` : ''}`}
      isOpen={showFilter}
      onOpen={() => setShowFilter(true)}
      onClosed={() => setShowFilter(false)}
      testId={props.testId}
    >
      <div className={styles.container}>
        <div className={styles.panelBody}>
          <LeafRadioField>
            {props.options.map((option, index) => (
              <LeafRadioFieldItem
                key={`${props.testId}-radio-${index}`}
                data-test-id={`${props.testId}-radio-${index}`}
                name="radioSelect"
                label={option.label}
                value={option.value}
                checked={option.value === props.choice}
                onChange={(e) => onChange(e.target.value)}
              />
            ))}
          </LeafRadioField>
        </div>
      </div>
    </FilterToggle>
  );
}

export default FilterPanelRadio;
