/* eslint-disable sonarjs/no-identical-functions */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { EnvironmentConfig } from './environment-config';
import EnvironmentContext, {
  type EnvironmentContextProps,
} from './environment.context';
import { useAppLogoPaths, useLandingImagePaths } from './hooks';

interface EnvironmentProviderProps<T> {
  children?: React.ReactNode;
  environment: EnvironmentConfig<T>;
}

const EnvironmentProvider = <T extends {} = {}>({
  children,
  environment,
}: EnvironmentProviderProps<T>) => {
  const [currEnvironment, setCurrEnvironment] = useState(environment);

  const appLogoPaths = useAppLogoPaths(currEnvironment);
  const landingImagePaths = useLandingImagePaths(currEnvironment);

  const setEnvironment = useCallback((newEnvironment: EnvironmentConfig) => {
    setCurrEnvironment((prevEnvironment) => ({
      ...prevEnvironment,
      ...newEnvironment,
    }));
  }, []);

  const value = useMemo<EnvironmentContextProps<T>>(
    () => ({
      environment: currEnvironment,
      setEnvironment,
      appLogoPaths,
      landingImagePaths,
    }),
    [appLogoPaths, currEnvironment, landingImagePaths],
  );

  return (
    <EnvironmentContext.Provider
      value={value as EnvironmentContextProps<unknown>}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

const EnvironmentProviderMultiTenant = <T extends {} = {}>({
  children,
  environment,
}: EnvironmentProviderProps<T>) => {
  const [currEnvironment, setCurrEnvironment] = useState(environment);

  const setEnvironment = useCallback((newEnvironment: EnvironmentConfig) => {
    setCurrEnvironment((prevEnvironment) => ({
      ...prevEnvironment,
      ...newEnvironment,
    }));
  }, []);

  const appType = window.sessionStorage.getItem('appType');

  useEffect(() => {
    setEnvironment(
      appType ? (environment[appType] as EnvironmentConfig<T>) : environment,
    );
  }, [appType, environment, setEnvironment]);

  const appLogoPaths = useAppLogoPaths(currEnvironment);
  const landingImagePaths = useLandingImagePaths(currEnvironment);

  const value = useMemo<EnvironmentContextProps<T>>(
    () => ({
      environment: currEnvironment,
      setEnvironment,
      appLogoPaths,
      landingImagePaths,
    }),
    [appLogoPaths, currEnvironment, setEnvironment, landingImagePaths],
  );

  return (
    <EnvironmentContext.Provider
      value={value as EnvironmentContextProps<unknown>}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export { EnvironmentProvider, EnvironmentProviderMultiTenant };
export default EnvironmentProvider;
