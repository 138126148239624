import type { ServerClassification } from '../../__model__/enum/server-classification';
import getData from '../../util/get-data';

export interface EndConversationProps {
  conversationId: string;
  serverType: ServerClassification;
}

export const endConversation = (params: EndConversationProps) =>
  getData({
    params: {
      ...params,
      action: 'endConversation',
    },
  });
