import type { APIRequestUserActivity } from '../../__model__/api/request/user-activity.model';
import { ConversationActivityEventType } from '../../__model__/enum/conversation/activity-type.enum';
import type { ServerClassification } from '../../__model__/enum/server-classification';
import getData from '../../util/get-data';

export interface UserIsTypingProps {
  conversationId: string;
  isTyping: boolean;
  serverType: ServerClassification;
}

export const userIsTyping = (props: UserIsTypingProps) =>
  getData<unknown, APIRequestUserActivity>({
    data: {
      activityType: props.isTyping
        ? ConversationActivityEventType.responding
        : ConversationActivityEventType.listening,
    },
    params: {
      action: 'userTyping',
      conversationId: props.conversationId,
      serverType: props.serverType,
    },
  });
