import { addSeconds, isAfter, isBefore, isEqual } from 'date-fns';
import debounce from 'lodash/debounce';
import findLast from 'lodash/findLast';
import noop from 'lodash/noop';
import type {
  APIResponseConversations,
  APIResponseConversationsActivityEvent,
  APIResponseConversationsEvent,
  APIResponseConversationsMessageCtaListDialogOption,
  APIResponseConversationsMessageDialogEvent,
  APIResponseConversationsMessageEvent,
  APIResponseConversationsParticipant,
  APIResponseConversationsParticipantEvent,
  APIResponseConversationsStateEvent,
  APIResponseConversationsTransferEvent,
  APIResponseConversationsWaitEvent,
} from '../__model__/api/response/conversation.model';
import type { Conversation as ConversationInterface } from '../__model__/conversation.model';
import { AgentActivitySubtype } from '../__model__/enum/agent-activity-subtype.enum';
import { ConversationActivityEventType } from '../__model__/enum/conversation/activity-type.enum';
import { ClosedReasonCode } from '../__model__/enum/conversation/close-reason-code.enum';
import { ConversationEventType } from '../__model__/enum/conversation/event-type.enum';
import { ConversationStateEventType } from '../__model__/enum/conversation/state-event-type.enum';
import type { DeviceType } from '../__model__/enum/device-type.enum';
import { EventEmmiterEventName } from '../__model__/enum/event-emmiter-event-name.enum';
import { MessageType } from '../__model__/enum/message-type.enum';
import { ParticipantRole } from '../__model__/enum/participant-role.enum';
import { ServerClassification } from '../__model__/enum/server-classification';
import { SystemMessageKey } from '../__model__/enum/system-message-key.enum';
import { WaitMessageTimingKey } from '../__model__/enum/wait-message-timing-key.enum';
import type { ConversationEmitterEvents } from '../__model__/event-emmiter.model';
import type { AgentActivityMessage } from '../__model__/message-type/agent-activity.model';
import {
  AGENT_IS_TYPING_ID,
  type AgentTypingMessage,
} from '../__model__/message-type/agent-typing.model';
import type {
  CtaListMessage,
  CtaListMessageOption,
} from '../__model__/message-type/cta-list.model';
import type { StandardMessage } from '../__model__/message-type/standard.model';
import {
  TRANSFER_ERROR_MSG_ID,
  type SystemMessage,
} from '../__model__/message-type/system.model';
import {
  WAIT_MSG_ID,
  type WaitMessage,
} from '../__model__/message-type/wait.model';
import type { Message, ViewableMessage } from '../__model__/message.model';
import type { Participant } from '../__model__/participant.model';
import type { ChatContext } from '../__model__/public-chat.model';
import { EventEmitter } from '../util/event-emitter';
import MessageList from '../util/message-list';
import { tempIdGen } from '../util/temp-id-generator';
import { updateOrRemoveOnKeyDiff } from '../util/update-or-remove-on-key-diff';
import { endConversation } from './network/end-convesation';
import { getConversation } from './network/get-conversation';
import { selectCtaOption } from './network/select-cta-options';
import { sendMessage } from './network/send-message';
import { transferConversastion } from './network/transfer-conversation';
import { userIsTyping } from './network/user-is-typing';
import { viewMessage } from './network/view-message';

export class Conversation implements ConversationInterface {
  /**
   * @generator
   * @static
   */
  static genUID = tempIdGen({ prefix: 'conversation_uid_' });

  #_id: string;
  #agentLeftMessages = new Set<Message>();
  #context: ChatContext;
  #created: Date;
  #ctaStack: CtaListMessage[] = [];
  #customer?: Participant;
  #deviceType: DeviceType;
  #eventEmitter = new EventEmitter<ConversationEmitterEvents, void>(undefined);
  #isClosed = true;
  #isInWaitingState = false;
  #isTyping = false;
  #lastViewedMessage?: Message;
  #messagelist = new MessageList();
  #meta: {
    -readonly [P in keyof ConversationInterface['meta']]: ConversationInterface['meta'][P];
  } = {};
  #onSelected?: (
    arg0: CtaListMessage,
    arg1: CtaListMessageOption,
    arg2: this['id'],
    arg3: this['serverType'],
  ) => void;
  #onSend?: (arg0: string) => void;
  #onUserTyping?: (arg0: boolean) => void;
  #onViewDebounce = debounce(
    (viewedMessage: ViewableMessage, conversationId, serverType) =>
      viewMessage({
        conversationId,
        eventId: viewedMessage._id,
        serverType,
      })
        .then(() => {
          this.#messagelist
            .filter(this.getCanMessageBeViewed(viewedMessage.date), this)
            .forEach(this.removeOnViewFromMsg, this);

          this.notifyUpdated();
        })
        .catch(noop),
    100,
    {
      maxWait: 200,
      trailing: true,
    },
  );
  #onViewed?: (
    arg0: Message,
    arg1: this['id'],
    arg3: this['serverType'],
  ) => void;
  #participants = new Map<Participant['_id'], Participant>();
  #processedEventIds = new Set<APIResponseConversationsEvent['_id']>();
  #serverClassification: ServerClassification = ServerClassification.bot;
  #shouldTransferOnSend = false;
  #ssoId?: string;
  #transferIds: Set<string> = new Set<string>();
  #uid = Conversation.genUID.next().value;
  #userPendingMessages = new Set<StandardMessage>();

  get uid() {
    return this.#uid;
  }

  constructor(data: APIResponseConversations, context: ChatContext) {
    this.#_id = data._id;
    this.#context = context;
    this.#created = new Date(data.created);
    this.#deviceType = context.deviceType;
    this.#eventEmitter = context.conversationEventEmitter;
    this.processSingleConversation(data);
  }

  private notifyUpdated() {
    this.#eventEmitter.trigger(
      EventEmmiterEventName.CONVERSATION_UPDATED,
      this as ConversationInterface,
    );
  }

  private removeOnViewFromMsg(_msg: Message) {
    this.#messagelist.replace(
      _msg._id,
      updateOrRemoveOnKeyDiff(_msg, 'onView', undefined),
    );
  }

  private getCanMessageBeViewed(lastViewedDate?: Date) {
    return (_msg: ViewableMessage) =>
      typeof _msg.onView === 'function' &&
      (_msg.participant?.role as unknown as ParticipantRole) !==
        ParticipantRole.customer &&
      (!lastViewedDate ||
        isBefore(_msg.date, lastViewedDate) ||
        isEqual(_msg.date, lastViewedDate));
  }

  private isParticipantRole(
    participantId: APIResponseConversationsParticipant['_id'],
    role: ParticipantRole,
  ) {
    return this.getParticipant(participantId)?.role === role;
  }

  private getParticipant(id: Participant['_id']) {
    /**
     * Note this techincally can still return undefined.
     * But if it does return undeinfed, there is a good
     * chance that the data that was given to this class
     * was bad or there is problem with processing the
     * data given to this class.
     */
    return this.#participants.get(id) as Participant;
  }

  private processActivityEvent(event: APIResponseConversationsActivityEvent) {
    const activityTypeHandler: Record<
      ConversationActivityEventType,
      () => unknown
    > = {
      [ConversationActivityEventType.responding]: () => {
        if (
          this.isParticipantRole(event.participantId, ParticipantRole.agent)
        ) {
          const msg: AgentTypingMessage = {
            _id: AGENT_IS_TYPING_ID,
            type: MessageType.agentIsTyping,
            date: new Date(event.created),
            participant: this.getParticipant(event.participantId),
          };
          this.#messagelist.add(msg, true);
        }
      },
      [ConversationActivityEventType.listening]: () => {
        if (
          this.isParticipantRole(event.participantId, ParticipantRole.agent)
        ) {
          this.#messagelist.remove(AGENT_IS_TYPING_ID);
        }
      },
      [ConversationActivityEventType.viewed]: () => {
        this.#messagelist
          .filter(this.getCanMessageBeViewed(), this)
          .forEach(this.removeOnViewFromMsg, this);
      },
    };

    activityTypeHandler[event.activityType]();
  }

  private convertAgentTransferedToAgentLeft() {
    this.#agentLeftMessages.forEach((msg: Message) => {
      this.#messagelist.replace(
        msg._id,
        updateOrRemoveOnKeyDiff(msg, 'subType', AgentActivitySubtype.left),
      );
    });
    this.#agentLeftMessages.clear();
  }

  private processParticipantEvent(
    event: APIResponseConversationsParticipantEvent,
  ) {
    const { _id, changeType: subType, created, participantId } = event;

    if (this.isParticipantRole(participantId, ParticipantRole.agent)) {
      const participant = this.getParticipant(participantId);

      const msg: AgentActivityMessage = {
        _id,
        date: new Date(created),
        participant,
        subType: subType as unknown as AgentActivitySubtype,
        type: MessageType.agentActivity,
      };

      if (subType === AgentActivitySubtype.join) {
        this.#messagelist.remove(WAIT_MSG_ID);
        this.#isInWaitingState = false;
        this.#meta.currentAgent = participant.channelId;
        this.convertAgentTransferedToAgentLeft();
      }

      if (subType === AgentActivitySubtype.left) {
        msg.subType = AgentActivitySubtype.transfer;
        const createdDate = new Date(created);
        const waitMsg: WaitMessage = {
          type: MessageType.wait,
          _id: WAIT_MSG_ID,
          date: createdDate,
          timing: [
            {
              time: addSeconds(createdDate, 15),
              key: WaitMessageTimingKey.botTrnasferInital,
            },
            {
              time: addSeconds(createdDate, 45),
              key: WaitMessageTimingKey.botTrnasferStillWaiting,
            },
          ],
        };
        this.#messagelist.add(waitMsg, true);
        this.#isInWaitingState = true;
        this.#agentLeftMessages.add(msg);
      }

      this.#messagelist.add(msg);
    }
  }

  private processStateEvent(event: APIResponseConversationsStateEvent) {
    const stateHandlers: Record<ConversationStateEventType, () => void> = {
      [ConversationStateEventType.opened]: () => {
        this.#isClosed = false;
        this.#isInWaitingState = false;
        this.#messagelist.remove(WAIT_MSG_ID);
      },
      [ConversationStateEventType.closed]: () => {
        this.#isClosed = true;
        this.#isInWaitingState = false;
        this.#messagelist.remove(AGENT_IS_TYPING_ID);
        this.#messagelist.remove(WAIT_MSG_ID);
        this.#meta.closeReason = event.reason;

        if (event.reason === ClosedReasonCode.TRANSFER) {
          delete this.#meta.closeReason;
          this.#shouldTransferOnSend = false;
        }

        if (
          ClosedReasonCode.AGENT_IDLE_TIMEOUT === event.reason ||
          ClosedReasonCode.AGENT_LEFT_TIMEOUT === event.reason ||
          ClosedReasonCode.AGENT_OFFLINE_TIMEOUT === event.reason ||
          ClosedReasonCode.AGENT_ONLINE_TIMEOUT === event.reason ||
          ClosedReasonCode.AWAITING_AGENT_TIMEOUT === event.reason ||
          ClosedReasonCode.USER_TIME_OUT === event.reason
        ) {
          const msg: SystemMessage = {
            _id: event._id,
            date: new Date(event.created),
            messageKey: SystemMessageKey.chatTimeout,
            onView: () => {
              this.onViewed?.(msg, this.#_id, this.#serverClassification);
            },
            type: MessageType.system,
          };

          this.#messagelist.add(msg);
        }

        if (
          event.reason === ClosedReasonCode.CLIENT_SIDE_TRANSFER_ERROR ||
          event.reason === ClosedReasonCode.TRANSFER_ERROR
        ) {
          const msg: SystemMessage = {
            _id: TRANSFER_ERROR_MSG_ID,
            date: new Date(),
            messageKey: SystemMessageKey.transferFailure,
            type: MessageType.system,
          };
          this.#messagelist.add(msg);
        }

        if (event.reason === ClosedReasonCode.AGENT_CLOSED) {
          this.convertAgentTransferedToAgentLeft();
        }
      },
      [ConversationStateEventType.transferManual]: () => {
        this.#shouldTransferOnSend = true;
      },
      [ConversationStateEventType.created]: noop,
    };

    /**
     * handlers only contains the state events
     * that we currently care about,
     * it does not hold all event states
     * that the server can send.
     *
     * Because of this do not remove
     * the `?.` no matter how much tyepscript,
     * leroy, or preettier complains.
     */
    stateHandlers[event.state]?.();
  }

  private processTransferEvent(event: APIResponseConversationsTransferEvent) {
    this.#shouldTransferOnSend = false;

    if (this.#transferIds.has(event.newConversationId)) {
      return;
    }

    getConversation({
      conversationId: event.newConversationId,
      serverType: ServerClassification.live,
      context: this.#context,
    }).then((data) => this.processSingleConversation(data));
  }

  private processWaitEvent(event: APIResponseConversationsWaitEvent) {
    const createdDate = new Date(event.created);
    const msg: WaitMessage = {
      type: MessageType.wait,
      _id: WAIT_MSG_ID,
      date: createdDate,
      timing: [
        {
          time: addSeconds(createdDate, 15),
          key: WaitMessageTimingKey.botTrnasferInital,
        },
        {
          time: addSeconds(createdDate, 45),
          key: WaitMessageTimingKey.botTrnasferStillWaiting,
        },
      ],
    };
    this.#messagelist.add(msg);
    this.#isInWaitingState = true;
  }

  private processDialogMessageEvent(
    event: APIResponseConversationsMessageDialogEvent,
  ) {
    const {
      _id,
      created,
      dialog,
      message,
      participantId,
      viewed: isViewed,
    } = event;

    const msg: CtaListMessage = {
      _id,
      date: new Date(created),
      message,
      participant: this.getParticipant(participantId),
      type: MessageType.cta_list,
    };

    if (!isViewed && msg.participant?.role !== ParticipantRole.customer) {
      msg.onView = () => {
        this.onViewed?.(msg, this.#_id, this.serverType);
      };
    }

    msg.options = dialog.ctaList?.options.map(
      (option: APIResponseConversationsMessageCtaListDialogOption) => {
        const msgOption: CtaListMessageOption = {
          ...option,
          onSelection: () => {
            this.onSelected?.(msg, msgOption, this.#_id, this.serverType);
            this.#messagelist.replace(
              msg._id,
              updateOrRemoveOnKeyDiff(msg, 'options', undefined),
            );
          },
        };
        return msgOption;
      },
    );

    this.#ctaStack.push(msg);
    this.#messagelist.add(msg);
  }

  private processStandardMessageEvent(
    event: APIResponseConversationsMessageEvent,
  ) {
    const { _id, created, message, participantId, viewed: isViewed } = event;
    const msg: StandardMessage = {
      _id,
      date: new Date(created),
      message,
      participant: this.getParticipant(participantId),
      type: MessageType.standard,
    };

    if (!isViewed && msg.participant?.role !== ParticipantRole.customer) {
      msg.onView = () => {
        this.onViewed?.(msg, this.#_id, this.serverType);
      };
    }

    for (const pendingMsg of this.#userPendingMessages.values()) {
      if (
        pendingMsg.message === msg.message &&
        pendingMsg.participant?._id &&
        msg.participant?._id &&
        pendingMsg.participant._id === msg.participant._id
      ) {
        this.#userPendingMessages.delete(pendingMsg);
        this.#messagelist.replace(pendingMsg._id, msg);
        /**
         * No reason to continue processing after we found the message
         * so lets break out of the for loop.
         */
        break;
      }
    }

    this.#messagelist.add(msg);

    const [ctaMsg, ...rest] = this.#ctaStack;
    this.#ctaStack = rest;

    if (ctaMsg) {
      this.#messagelist.replace(
        ctaMsg._id,
        updateOrRemoveOnKeyDiff(ctaMsg, 'options', undefined),
      );
    }
  }

  private processEvents(events: APIResponseConversations['events']) {
    const processOncePerConversation =
      <T>(fn: (arg1: T) => void) =>
      (event: APIResponseConversationsEvent) => {
        if (!this.#processedEventIds.has(event._id)) {
          this.#processedEventIds.add(event._id);
          fn(event as T);
        }
      };

    const handlers = {
      [ConversationEventType.activity]:
        processOncePerConversation<APIResponseConversationsActivityEvent>(
          (event) => this.processActivityEvent(event),
        ),
      [ConversationEventType.message]: (event: APIResponseConversationsEvent) =>
        (event as APIResponseConversationsMessageDialogEvent).dialog?.type ===
        'cta_list'
          ? handlers.dialogMessage(
              event as APIResponseConversationsMessageDialogEvent,
            )
          : handlers.standardMessage(
              event as APIResponseConversationsMessageEvent,
            ),

      [ConversationEventType.participant]:
        processOncePerConversation<APIResponseConversationsParticipantEvent>(
          (event) => this.processParticipantEvent(event),
        ),
      [ConversationEventType.state]:
        processOncePerConversation<APIResponseConversationsStateEvent>(
          (event) => this.processStateEvent(event),
        ),
      [ConversationEventType.transfer]:
        processOncePerConversation<APIResponseConversationsTransferEvent>(
          (event) => this.processTransferEvent(event),
        ),
      [ConversationEventType.wait]:
        processOncePerConversation<APIResponseConversationsWaitEvent>((event) =>
          this.processWaitEvent(event),
        ),
      dialogMessage: (event: APIResponseConversationsMessageDialogEvent) =>
        this.processDialogMessageEvent(event),
      standardMessage: (event: APIResponseConversationsMessageEvent) =>
        this.processStandardMessageEvent(event),
    };

    events.forEach((event) => {
      /**
       * handlers only contains the event
       * types that we currently care about,
       * it does not hold all event types
       * that the server can send.
       *
       * Because of this do not remove
       * the `?.` no matter how much tyepscript,
       * leroy, or preettier complains.
       */
      handlers[event.type]?.(event);
    });
    return this;
  }

  private processsConverrsationMeta(data: APIResponseConversations) {
    this.#serverClassification =
      data.type === 'bot'
        ? ServerClassification.bot
        : ServerClassification.live;

    this.#ssoId = data.ssoId;

    if (this.#_id !== data._id) {
      this.#_id = data._id;
    }

    this.#created = new Date(data.created);

    data.transferIds.forEach((id) => this.#transferIds.add(id));
    this.#transferIds.add(this.#_id);

    if (data.context.meta.ENGAGEMENT_ID) {
      const [sessionId, taskId] = data.context.meta.ENGAGEMENT_ID.split('|');
      this.#meta.sessionId = sessionId;
      this.#meta.taskId = taskId;
    }

    this.#meta.type = data.type;
    this.#meta.subType = 'async';

    return this;
  }

  private processParticipants(
    participants: APIResponseConversationsParticipant[],
  ) {
    participants.forEach((participant) => {
      this.#participants.set(participant._id, participant);
      if (participant.role === ParticipantRole.customer) {
        this.#customer = participant;
      }
    });

    return this;
  }

  private processSingleConversation(
    data?: APIResponseConversations,
    { triggerUpdate = true } = {},
  ) {
    if (!data) {
      return this;
    }

    if (data.status === ClosedReasonCode.TRANSFER_ERROR) {
      data.events.push({
        state: ConversationStateEventType.closed,
        type: ConversationEventType.state,
        _id: `internal_${this.#messagelist.getTempId()}`,
        created: new Date().toString(),
        reason: data.status,
      });
    }

    this.processParticipants(data.participants)
      .processEvents(data.events)
      .processsConverrsationMeta(data);

    if (triggerUpdate) {
      this.notifyUpdated();
    }
    return this;
  }

  update() {
    if (!this.id) {
      return this;
    }

    getConversation({
      conversationId: this.id,
      serverType: this.serverType,
      context: this.#context,
    })
      .then((data) => this.processSingleConversation(data))
      .catch(noop);

    return this;
  }

  get created() {
    return this.#created;
  }

  get unreadCount() {
    return this.#messagelist.filter(this.getCanMessageBeViewed(), this).length;
  }

  get messages() {
    return this.#messagelist.list;
  }

  get isClosed() {
    return this.#isClosed;
  }

  get id() {
    return this.#_id;
  }

  get onEnd() {
    return !this.isClosed && this.serverType === ServerClassification.live
      ? () => {
          this.#isClosed = true;
          this.#meta.closeReason = ClosedReasonCode.USER_CLOSED;
          this.notifyUpdated();
          return endConversation({
            conversationId: this.#_id,
            serverType: this.serverType,
          })
            .catch(noop) // if endConversation fails
            .finally(() => this.update())
            .catch(noop); // To make sure there are no unhandled promise exceptions
        }
      : undefined;
  }

  get onSelected() {
    if (!this.#onSelected) {
      this.#onSelected = (
        msg: CtaListMessage,
        msgOption: CtaListMessageOption,
        conversationId: this['id'],
        serverType: this['serverType'],
      ) =>
        selectCtaOption({
          conversationId,
          messageId: msg._id,
          optionId: msgOption._id,
          optionValue: msgOption.value,
          serverType: serverType as ServerClassification,
        })
          .then(() => this.update())
          .catch(noop);
    }

    return this.isClosed ? undefined : this.#onSelected;
  }

  get onSend() {
    if (!this.#onSend) {
      this.#onSend = (text: string) => {
        const _text = text.trim();

        if (!_text.length) {
          return Promise.resolve();
        }

        const msg: StandardMessage = {
          _id: this.#messagelist.getTempId(),
          date: new Date(),
          message: _text,
          participant: this.#customer,
          type: MessageType.standard,
        };

        this.#messagelist.add(msg);
        this.#userPendingMessages.add(msg);

        this.notifyUpdated();

        if (this.#shouldTransferOnSend) {
          return transferConversastion({
            converstionId: this.#_id,
            serverType: ServerClassification.live,
            deviceType: this.#deviceType,
            text: _text,
          })
            .then(async (data) => {
              this.#shouldTransferOnSend = false;
              const { prevConversation, nextConversation } = data;

              this.processSingleConversation(prevConversation, {
                triggerUpdate: false,
              }).processSingleConversation(nextConversation);

              this.#messagelist.remove(msg._id);
              this.#userPendingMessages.delete(msg);
            })
            .catch(async (e) => {
              this.processStateEvent({
                state: ConversationStateEventType.closed,
                type: ConversationEventType.state,
                _id: `internal_${this.#messagelist.getTempId()}`,
                created: new Date().toString(),
                reason: ClosedReasonCode.CLIENT_SIDE_TRANSFER_ERROR,
              });
            })
            .finally(() => {
              this.notifyUpdated();
            })
            .catch(noop);
        }

        return sendMessage({
          conversationId: this.#_id,
          serverType: this.serverType,
          text: _text,
        })
          .then((data) => {
            const newMsg: StandardMessage = {
              _id: data._id,
              date: new Date(data.created),
              message: data.message,
              participant: this.getParticipant(data.participantId),
              type: MessageType.standard,
            };

            this.#messagelist.replace(msg._id, newMsg);
            this.#userPendingMessages.delete(msg);
            this.notifyUpdated();
          })
          .catch(noop);
      };
    }

    return this.isClosed || this.#isInWaitingState ? undefined : this.#onSend;
  }

  get onUserTyping(): ((arg0: boolean) => void) | undefined {
    if (!this.#onUserTyping) {
      this.#onUserTyping = (isTyping: boolean) => {
        if (this.#isTyping !== isTyping) {
          this.#isTyping = isTyping;
          userIsTyping({
            conversationId: this.#_id,
            isTyping,
            serverType: this.serverType,
          })
            // don't really care if this fails
            .catch(noop);
        }
      };
    }

    return this.isClosed ? undefined : this.#onUserTyping;
  }

  get onViewed() {
    if (!this.#onViewed) {
      this.#onViewed = (
        msg: Message,
        conversationId: this['id'],
        serverType: this['serverType'],
      ) => {
        const isLastMessage =
          !this.#lastViewedMessage ||
          isAfter(msg.date, msg.date) ||
          isEqual(msg.date, msg.date);

        if (isLastMessage) {
          this.#lastViewedMessage = msg;
          const unviewedMessages = this.#messagelist.filter(
            this.getCanMessageBeViewed(msg.date),
            this,
          );
          const lastTrackedUnviewedMessage = findLast(
            unviewedMessages,
            (_msg) =>
              _msg.type === MessageType.standard ||
              _msg.type === MessageType.cta_list,
          );

          if (lastTrackedUnviewedMessage) {
            this.#onViewDebounce(
              lastTrackedUnviewedMessage,
              conversationId,
              serverType,
            );
          }

          unviewedMessages.forEach(this.removeOnViewFromMsg, this);

          this.notifyUpdated();
        }
      };
    }

    return this.#onViewed;
  }

  get serverType(): ServerClassification {
    return this.#serverClassification;
  }

  get transferIds(): Set<string> {
    return this.#transferIds;
  }

  get ssoId() {
    return this.#ssoId;
  }

  get meta() {
    return Object.freeze({ ...this.#meta });
  }
}

export default Conversation;
