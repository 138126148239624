const upperCaseRegex = /^[A-Z]$/;
const lowerCaseRegex = /^[a-z]$/;
const numberRegex = /^[0-9]$/;
const symbolRegex = /^[!.&@$<>+=_{}<>|"'^/:()#%;,*~\\`-]$/;

export interface PasswordOptionsProps {
  minLength: number;
  minLowercase: number;
  minUppercase: number;
  minNumbers: number;
  minSymbols: number;
  pointsPerUnique?: number;
  pointsPerRepeat?: number;
  pointsForContainingLower?: number;
  pointsForContainingUpper?: number;
  pointsForContainingNumber?: number;
  pointsForContainingSymbol?: number;
}

const defaultOptions: PasswordOptionsProps = {
  minLength: 9,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 1,
  pointsPerUnique: 1,
  pointsPerRepeat: 1,
  pointsForContainingLower: 20,
  pointsForContainingUpper: 20,
  pointsForContainingNumber: 20,
  pointsForContainingSymbol: 20,
};

export interface AnalysisProps {
  length: number;
  uniqueChars: number;
  uppercaseCount: number;
  lowercaseCount: number;
  numberCount: number;
  symbolCount: number;
}

export interface ValidatedPasswordProps {
  isValidPassword: boolean;
  isValidLength: boolean;
  isContainLowercase: boolean;
  isContainUppercase: boolean;
  isContainNumbers: boolean;
  isContainSymbols: boolean;
  passwordScore: number;
}

/* Counts number of occurrences of each char in a string
 */
function countChars(str: string) {
  const result: Record<string, number> = {};
  Array.from(str).forEach((char: string) => {
    const curVal = result[char];
    if (curVal) {
      result[char] += 1;
    } else {
      result[char] = 1;
    }
  });
  return result;
}

/* Return information about a password */
function analyzePassword(password: string): AnalysisProps {
  const charMap = countChars(password);
  const analysis: AnalysisProps = {
    length: password.length,
    uniqueChars: Object.keys(charMap).length,
    uppercaseCount: 0,
    lowercaseCount: 0,
    numberCount: 0,
    symbolCount: 0,
  };
  Object.keys(charMap).forEach((char) => {
    /* istanbul ignore else */
    if (upperCaseRegex.test(char)) {
      analysis.uppercaseCount += charMap[char];
    } else if (lowerCaseRegex.test(char)) {
      analysis.lowercaseCount += charMap[char];
    } else if (numberRegex.test(char)) {
      analysis.numberCount += charMap[char];
    } else if (symbolRegex.test(char)) {
      analysis.symbolCount += charMap[char];
    }
  });
  return analysis;
}

const scorePassword = (
  analysis: AnalysisProps,
  scoringOptions: PasswordOptionsProps,
): number => {
  let points = 0;
  points += analysis.uniqueChars * (scoringOptions.pointsPerUnique || 1);
  points +=
    (analysis.length - analysis.uniqueChars) *
    (scoringOptions.pointsPerRepeat || 1);
  if (analysis.lowercaseCount > 0) {
    points += scoringOptions.pointsForContainingLower || 1;
  }
  if (analysis.uppercaseCount > 0) {
    points += scoringOptions.pointsForContainingUpper || 1;
  }
  if (analysis.numberCount > 0) {
    points += scoringOptions.pointsForContainingNumber || 1;
  }
  if (analysis.symbolCount > 0) {
    points += scoringOptions.pointsForContainingSymbol || 1;
  }
  return points;
};

export const validatePassword = (
  password: string,
  options: PasswordOptionsProps,
): ValidatedPasswordProps => {
  const analysis = analyzePassword(password);
  const mergeoptions = { ...defaultOptions, ...options };
  const score = scorePassword(analysis, mergeoptions);
  return {
    isValidPassword:
      analysis.length >= mergeoptions.minLength &&
      analysis.lowercaseCount >= mergeoptions.minLowercase &&
      analysis.uppercaseCount >= mergeoptions.minUppercase &&
      analysis.numberCount >= mergeoptions.minNumbers &&
      analysis.symbolCount >= mergeoptions.minSymbols,
    isValidLength: analysis.length >= mergeoptions.minLength,
    isContainLowercase:
      analysis.lowercaseCount > 0 &&
      analysis.lowercaseCount >= mergeoptions.minLowercase,
    isContainUppercase:
      analysis.uppercaseCount > 0 &&
      analysis.uppercaseCount >= mergeoptions.minUppercase,
    isContainNumbers:
      analysis.numberCount > 0 &&
      analysis.numberCount >= mergeoptions.minNumbers,
    isContainSymbols:
      analysis.symbolCount > 0 &&
      analysis.symbolCount >= mergeoptions.minSymbols,
    passwordScore: score,
  };
};
