import type { PropsWithChildren } from 'react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  LeafBreadcrumbs,
  LeafBreadcrumbsItem,
  LeafButton,
  LeafHeading,
} from '@esi/leaf-react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { usePrevious } from '@cigna/enac-provider-web/shared/util';
import type { PageViewEvent } from '@cigna/shared/analytics/core';
import { useTrackPageView } from '@cigna/shared/react/analytics-util';
import { Head } from '@cigna/shared/react/head-util';
import styles from './page-wrapper.module.scss';

export interface PageWrapperProps {
  variation: 'parent' | 'child' | 'custom';
  pageTitle: string;
  analytics?: PageViewAnalytics;
  backgroundPattern?: 'topRight' | 'topRightAlt' | 'bottomRight';
  breadcrumbs?: BreadcrumbItem[];
  parentPagePath?: string;
  hasPrintButton?: boolean;
  customHeader?: React.ReactElement;
  disablePageWrap?: boolean;
  disablePadding?: boolean;
}

export interface BreadcrumbItem {
  label: string;
  href?: string;
}

export type PageViewAnalytics = Required<
  Pick<PageViewEvent, 'pageData' | 'reqData'>
>;

interface PageHeadProps {
  title: string;
}

const PageHead = (props: PageHeadProps) => (
  <Head>
    <title>
      {props.title === 'index' ? '' : `${props.title} | `}Evernorth Accountable
      Care for Providers
    </title>
  </Head>
);

const Crumbs = (props: { breadcrumbs: BreadcrumbItem[] }) => (
  <LeafBreadcrumbs
    className="enac-g-hide-for-print"
    style={{ marginBottom: '0.75em', marginTop: '0.75rem' }}
    data-test-id="breadcrumbs"
  >
    <>
      {props.breadcrumbs.map((crumb, index) => (
        <LeafBreadcrumbsItem
          data-test-id="breadcrumb-item"
          key={`crumb-${index}`}
        >
          {crumb.href ? (
            <Link to={crumb.href}>{crumb.label}</Link>
          ) : (
            crumb.label
          )}
        </LeafBreadcrumbsItem>
      ))}
    </>
  </LeafBreadcrumbs>
);

export function PageWrapper(props: PropsWithChildren<PageWrapperProps>) {
  const navigate = useNavigate();
  const handleNavigation = () => {
    if (props.parentPagePath) {
      navigate(props.parentPagePath);
    } else {
      navigate(-1);
    }
  };
  const trackPageView = useTrackPageView();

  const previousAnalytics = usePrevious(props.analytics);

  if (props.analytics && !isEqual(previousAnalytics, props.analytics)) {
    trackPageView({ ...props.analytics, event: 'pageLoad' });
  }

  if (props.variation === 'custom') {
    return (
      <main
        className={classNames(
          { [styles.containerCustom]: true },
          { [styles.containerPadding]: !props.disablePadding },
          {
            [styles.containerBgTopRight]:
              props.backgroundPattern === 'topRight',
          },
          {
            [styles.containerBgTopRightAlt]:
              props.backgroundPattern === 'topRightAlt',
          },
          {
            [styles.containerBgBottomRight]:
              props.backgroundPattern === 'bottomRight',
          },
        )}
      >
        <PageHead title={props.pageTitle} />

        {props.disablePageWrap ? (
          props.children
        ) : (
          <div className="enac-g-page-wrap">{props.children}</div>
        )}
      </main>
    );
  }

  return (
    <main className={styles.containerDefault}>
      <PageHead title={props.pageTitle} />
      <div className={styles.containerDefaultTop}>
        <div className="enac-g-page-wrap">
          {props.breadcrumbs?.length && (
            <Crumbs breadcrumbs={props.breadcrumbs} />
          )}
          <div className={styles.containerHeaderWrap}>
            {props.variation === 'child' && (
              <LeafButton
                className="enac-g-hide-for-print"
                iconName="arrow-back"
                text="Go Back"
                variant="tertiary"
                hideText={true}
                onClick={() => handleNavigation()}
              />
            )}

            <LeafHeading
              tagVariant="h1"
              type="title-xlarge"
              data-test-id="heading-main"
            >
              {props.pageTitle}
            </LeafHeading>

            {props.hasPrintButton && (
              <LeafButton
                hideText
                variant="tertiary"
                iconName="x-hk-print-button"
                data-test-id="btn-print"
                className={styles.printBtn}
                onClick={() => window.print()}
              ></LeafButton>
            )}
          </div>
          {props.customHeader && <div>{props.customHeader}</div>}
        </div>
      </div>
      <div className={styles.containerDefaultBottom}>
        <div className="enac-g-page-wrap">
          <div>{props.children}</div>
        </div>
      </div>
    </main>
  );
}

export default PageWrapper;
