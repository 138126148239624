import type { PageEvent } from '../models';

declare global {
  interface Window {
    adobeDataLayer: PageEvent[] | undefined;
  }
}

export class AdobeDataLayer {
  private adobeDataLayer = window.adobeDataLayer || [];

  addEvent(event: PageEvent): void {
    this.adobeDataLayer.push(event);
  }

  public getAdobeDataLayer(): PageEvent[] {
    return this.adobeDataLayer;
  }
  public resetAdobeDataLayer(): void {
    // Adobe overrides the array and adds items to it and the end, but does not count them as the length.
    this.adobeDataLayer.splice(0, this.adobeDataLayer.length);
  }
}

export default AdobeDataLayer;
