import type { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import {
  LeafBadge,
  LeafButton,
  LeafDivider,
  LeafHeading,
} from '@esi/leaf-react';
import styles from './edit-link-card.module.scss';

export interface EditLinkCardProps {
  headline: string;
  actionText: string;
  link: string;
  description: string;
  status?: 'success' | 'error';
  statusText?: string;
  testId?: string;
}

export function EditLinkCard(props: PropsWithChildren<EditLinkCardProps>) {
  return (
    <div className={styles.editLinkCard} data-test-id={props.testId}>
      <div className={styles.topRow}>
        <div>
          <div>
            <LeafHeading
              tagVariant="h3"
              type="title-default"
              data-test-id="edit-link-card-header"
            >
              {props.headline}
            </LeafHeading>
          </div>
          <div
            className={styles.desscription}
            data-test-id={`${props.testId ?? 'edit-link-card'}-description`}
          >
            {props.description}
          </div>
        </div>
        <div className={styles.statusBadge}>
          {props.status && props.statusText && (
            <LeafBadge
              status={props.status}
              variant="light"
              text={props.statusText}
            ></LeafBadge>
          )}
        </div>
      </div>
      {props.children && (
        <div className={styles.childContainer}>{props.children}</div>
      )}
      <LeafDivider />
      <div
        className={styles.bottomRow}
        data-test-id={`${props.testId ?? 'edit-link-card'}-edit-button`}
      >
        <Link to={props.link}>
          <LeafButton text={props.actionText} variant="tertiary" />
        </Link>
      </div>
    </div>
  );
}

export default EditLinkCard;
