import type { EnvironmentConfig } from './environment-config';

const rootApiUrl = 'https://d-health-kit.dev.digitaledge.evernorth.com';
const rootOnboardingPublicUrl =
  'https://d-public.dev.digitaledge.evernorth.com/';
const routeBaseName = '/consumer';

export const mockEnvironment: EnvironmentConfig = {
  auth0: {
    audience: rootApiUrl,
    clientId: 'q8cG9qqcdG07uBHmMZTIjR48R37cOKIr',
    domain: 'poc.universal.login.evernorth.com',
    scope: 'openid profile email',
    redirect_uri: `${window.location.origin}${routeBaseName}`,
    impersonationConnection: 'workforce',
    impersonationReturnTo: '/home',
  },
  paymetricAjaxUrl: 'https://cert-xiecomm.paymetric.com/DIeComm/Ajax',
  rootApiUrl,
  rootOnboardingPublicUrl,
  routeBaseName,
  production: false,
  debug: false,
  google: {
    placesApiKey: 'AIzaSyBWMS3kE3mlYnv2EZwarCt544IgxwqiqVQ',
  },
  baseAppUrl: 'https://static.evernorth.com',
  appLogoName: 'evernorth-hs',
  brandName: 'evernorth-hs',
};
