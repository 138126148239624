import memoize from 'lodash/memoize';
import noop from 'lodash/noop';
import { EventEmmiterEventName } from '../../__model__/enum/event-emmiter-event-name.enum';
import type { ChatContext } from '../../__model__/public-chat.model';
import { getAvailability } from '../../lib/network/get-availability';
import { poller } from '../../util/polling';

const SYM_ACTIVE = '__active__';

const getAvailabilityPoller = memoize((context: ChatContext) => {
  const handler = memoize(
    () =>
      getAvailability()
        .then(async (data) =>
          context.privateEvents.trigger(
            EventEmmiterEventName.OMNI_AVAIALABLITY_LOADED,
            data,
          ),
        )
        .finally(() => {
          handler.cache.delete(SYM_ACTIVE);
        })
        .catch(noop),
    () => SYM_ACTIVE,
  );
  return poller(handler);
});

export const startAvailabilityPolling = (context: ChatContext) =>
  (context.hasPollingEnabled ? getAvailabilityPoller(context).start : noop)();
export const stopAvailabilityPolling = (context: ChatContext) =>
  getAvailabilityPoller(context).stop();
export const setAvailabilityPollingTime = (
  context: ChatContext,
  time?: number,
) => {
  const { getFromPrivateSnapshot } = context;

  let _defaultTiming =
    typeof time === 'number'
      ? time
      : getFromPrivateSnapshot<number>('omni.polling.availability') ?? -1;
  _defaultTiming = context.hasPollingEnabled ? _defaultTiming : -1;
  getAvailabilityPoller(context).setTime(_defaultTiming);
};
