import { useNavigationApi } from '../../api/use-navigation-api';
import MainNavigationItem from '../main-navigation-item/main-navigation-item';
import styles from './main-navigation.module.scss';

export function MainNavigation() {
  const { data, isLoading, isError } = useNavigationApi();
  return (
    <nav aria-label="Main" className={styles.nav}>
      <div className="enac-g-page-wrap">
        {data?.navigation.map((link) => (
          <MainNavigationItem
            link={link}
            key={link.identifier}
          ></MainNavigationItem>
        ))}
      </div>
    </nav>
  );
}

export default MainNavigation;
