/* eslint-disable sonarjs/no-duplicate-string */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import {
  LeafButton,
  LeafField,
  LeafHeading,
  LeafInlineAlert,
} from '@esi/leaf-react';
import isEmail from 'validator/lib/isEmail';
import { PageWrapper } from '@cigna/enac-provider-web/shared/ui';
import { CUSTOMER_SUPPORT_NUMBER } from '@cigna/enac-provider-web/shared/util';
import { useTrackPageAction } from '@cigna/shared/react/analytics-util';
import { useForgotPasswordApi } from '../../api/use-forgot-password-api';
import styles from './forgot-password.module.scss';
export function ForgotPassword() {
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();

  const trackPageAction = useTrackPageAction();

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    register,
  } = useForm<{
    firstName: string;
    lastName: string;
    emailAddr: string;
    employeeId: string;
  }>({
    mode: 'onTouched',
    defaultValues: {
      firstName: '',
      lastName: '',
      emailAddr: '',
      employeeId: '',
    },
  });

  const { mutate, isLoading, isError } = useForgotPasswordApi();

  const submitResetPassword = () => {
    trackPageAction(
      {
        controlText: 'Reset Password',
        controlType: 'Click',
        controlName: 'reset-password-link',
      },
      {
        reqData: {
          requirementID: 'ac-Log008',
          userStory: 'ACNBPT4-282',
        },
      },
    );
    setShowErrorMessage(false);
    mutate(getValues(), {
      onSuccess: (res) => {
        if (res.metadata.outcome.status === 200) {
          setIsEmailSent(true);
        } else {
          setShowErrorMessage(true);
        }
      },
      onError: () => {
        setShowErrorMessage(true);
      },
    });
  };

  if (isEmailSent) {
    return (
      <PageWrapper
        variation="custom"
        pageTitle={t('forgotPassword.success.pageTitle')}
        analytics={{
          pageData: {
            pageName: 'Forgot password check inbox',
            pageSection: 'Access',
            pageType: 'Login',
            pageAuthenticationType: 'Unauthenticated',
            site: 'ENAC',
          },
          reqData: {
            requirementID: 'pv-Log008',
            userStory: 'ACNBPT4-282',
          },
        }}
      >
        <LeafInlineAlert status="success" iconName="info" fullWidth>
          <LeafHeading
            tagVariant="h1"
            type="title-xlarge"
            data-test-id="heading-main-success"
          >
            {t('forgotPassword.success.title')}
          </LeafHeading>
          <p>{t('forgotPassword.success.message')}</p>
        </LeafInlineAlert>
        <div className="enac-g-margin-top-32">
          <LeafButton
            data-test-id="btn-login"
            onClick={() => {
              loginWithRedirect({
                appState: { prompt: 'login', returnTo: '/dashboard' },
              });
            }}
            text={`${t('forgotPassword.success.loginBtn')}`}
          />
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper
      variation="custom"
      pageTitle={t('forgotPassword.title')}
      analytics={{
        pageData: {
          pageName: 'Forgot password',
          pageSection: 'Access',
          pageType: 'Login',
          pageAuthenticationType: 'Unauthenticated',
          site: 'ENAC',
        },
        reqData: {
          requirementID: 'pv-Log007',
          userStory: 'ACNBPT4-282',
        },
      }}
    >
      <LeafHeading
        tagVariant="h1"
        type="title-xlarge"
        data-test-id="heading-main"
      >
        {t('forgotPassword.title')}
      </LeafHeading>
      <p>{t('forgotPassword.subTitle')}</p>
      <form
        onSubmit={handleSubmit(submitResetPassword)}
        data-test-id="form-identity"
        className="enac-g-margin-top-32 enac-g-standard-form"
      >
        <LeafField
          {...register('firstName', {
            required: `${t('forgotPassword.form.firstName.required')}`,
          })}
          data-test-id="input-first-name"
          label={t('forgotPassword.form.firstName.label')}
          isError={!!errors.firstName}
          errorNote={errors.firstName?.message}
          autocomplete="given-name"
        ></LeafField>
        <LeafField
          {...register('lastName', {
            required: `${t('forgotPassword.form.lastName.required')}`,
          })}
          data-test-id="input-last-name"
          label={t('forgotPassword.form.lastName.label')}
          isError={!!errors.lastName}
          errorNote={errors.lastName?.message}
          autocomplete="family-name"
        ></LeafField>

        <LeafField
          {...register('emailAddr', {
            required: `${t('forgotPassword.form.emailAddress.required')}`,
            validate: (email) =>
              isEmail(email)
                ? true
                : t('forgotPassword.form.emailAddress.notMatchFormate'),
          })}
          type="email"
          data-test-id="input-email-address"
          label={t('forgotPassword.form.emailAddress.label')}
          isError={!!errors.emailAddr}
          errorNote={errors.emailAddr?.message}
          autocomplete="email"
        ></LeafField>

        <LeafField
          {...register('employeeId', {
            required: `${t('forgotPassword.form.employeeId.required')}`,
          })}
          data-test-id="input-employee-id"
          label={t('forgotPassword.form.employeeId.label')}
          isError={!!errors.employeeId}
          errorNote={errors.employeeId?.message}
        ></LeafField>

        <LeafButton
          data-test-id="btn-submit"
          type="submit"
          text={`${t('forgotPassword.form.submitBtn')}`}
          disabled={isLoading}
        />
        {(isError || showErrorMessage) && (
          <div className={styles.errorContainer}>
            <LeafInlineAlert
              status="error"
              iconName="error-alt"
              fullWidth
              data-test-id="error-message-container"
            >
              <LeafHeading
                tagVariant="h3"
                type="title-small"
                data-test-id="heading-error-success"
              >
                {t('forgotPassword.error.title')}
              </LeafHeading>
              <p>
                <Trans
                  ns="enac-shell"
                  i18nKey="forgotPassword.error.message"
                  values={{ number: CUSTOMER_SUPPORT_NUMBER }}
                  components={{
                    bold: <strong />,
                  }}
                />
              </p>
            </LeafInlineAlert>
          </div>
        )}
      </form>
    </PageWrapper>
  );
}

export default ForgotPassword;
