import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { LeafButton, LeafField, LeafTag, LeafTagList } from '@esi/leaf-react';
import {
  type Duration,
  format,
  parse,
  sub,
  isSameDay,
  isToday,
} from 'date-fns';
import { apiStandardDateFormat } from '@cigna/enac-provider-web/shared/util';
import FilterToggle from '../filter-toggle/filter-toggle';
import styles from './filter-panel-date.module.scss';

export interface FilterPanelDateProps {
  btnLabel: string;
  rangeOptions: DaterangeOption[];
  startDate: string;
  endDate: string;
  onApply: (value: FormValues) => void;
  onCancel?: () => void;
  testId: string;
}

interface FormValues {
  startDate: string;
  endDate: string;
}

export interface DaterangeOption {
  label: string;
  duration: Duration;
}

export function FilterPanelDate(props: FilterPanelDateProps) {
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const { register, watch, reset, getValues, setValue } = useForm<FormValues>({
    defaultValues: {
      startDate: props.startDate || format(new Date(), apiStandardDateFormat),
      endDate: props.endDate || format(new Date(), apiStandardDateFormat),
    },
  });

  const watchEndDate = watch('endDate');
  // not used but triggers change detection
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const watchStartDate = watch('startDate');

  const maxStartDate = format(
    parse(watchEndDate, apiStandardDateFormat, new Date()),
    apiStandardDateFormat,
  );
  const maxEndDate = format(new Date(), apiStandardDateFormat);

  const handleRangeChoice = (duration: Duration) => {
    setValue(
      'startDate',
      format(sub(new Date(), duration), apiStandardDateFormat),
    );
    setValue('endDate', format(new Date(), apiStandardDateFormat));
  };

  const handleApply = () => {
    props.onApply({
      startDate: getValues('startDate'),
      endDate: getValues('endDate'),
    });
    setShowFilter(false);
  };

  const handleCancel = () => {
    reset();
    setShowFilter(false);
  };

  const handleOpen = () => {
    setValue(
      'startDate',
      props.startDate || format(new Date(), apiStandardDateFormat),
    );
    setValue(
      'endDate',
      props.endDate || format(new Date(), apiStandardDateFormat),
    );
    setShowFilter(true);
  };

  const handleClose = () => {
    setShowFilter(false);
  };

  const getDurationLabel = (startDate: Date, endDate: Date): string => {
    const endsToday = isToday(new Date(endDate));

    if (endsToday) {
      if (isSameDay(startDate, sub(new Date(), { days: 30 }))) {
        return 'Last 30 days';
      } else if (isSameDay(startDate, sub(new Date(), { days: 60 }))) {
        return 'Last 60 days';
      } else if (isSameDay(startDate, sub(new Date(), { days: 90 }))) {
        return 'Last 90 days';
      } else if (isSameDay(startDate, sub(new Date(), { years: 2 }))) {
        return 'Last 2 years';
      }
    }

    return 'Custom range';
  };

  const currentDurationLabel = getDurationLabel(
    parse(getValues('startDate'), apiStandardDateFormat, new Date()),
    parse(getValues('endDate'), apiStandardDateFormat, new Date()),
  );

  const selectedDurationLabel = getDurationLabel(
    parse(props.startDate, apiStandardDateFormat, new Date()),
    parse(props.endDate, apiStandardDateFormat, new Date()),
  );

  return (
    <FilterToggle
      label={`${props.btnLabel}: ${selectedDurationLabel}`}
      isOpen={showFilter}
      onOpen={handleOpen}
      onClosed={handleClose}
      panelWidth="375px"
      testId={props.testId}
    >
      <div data-test-id={'filter-toggle-content'}>
        <div>
          <LeafTagList>
            {props.rangeOptions.map((option) => (
              <LeafTag
                key={option.label}
                text={option.label}
                variant={
                  currentDurationLabel === option.label ? 'check' : undefined
                }
                onClick={() => handleRangeChoice(option.duration)}
              />
            ))}
          </LeafTagList>
          <div className={styles.dateInputs}>
            <LeafField
              {...register(`startDate`)}
              type="date"
              label="Start date"
              data-test-id="input-start-date"
              max={maxStartDate}
            />
            <LeafField
              {...register(`endDate`)}
              type="date"
              label="End date"
              data-test-id="input-end-date"
              max={maxEndDate}
            />
          </div>
        </div>
        <div className={styles.panelFooter}>
          <LeafButton
            data-test-id={`${props.testId}-apply-btn`}
            onClick={handleApply}
            text="Apply"
            variant="primary"
            size="sm"
            fullWidth
          />
          <LeafButton
            data-test-id={`${props.testId}-cancel-btn`}
            onClick={handleCancel}
            text="Cancel"
            variant="tertiary"
            size="sm"
            fullWidth
          />
        </div>
      </div>
    </FilterToggle>
  );
}

export default FilterPanelDate;
