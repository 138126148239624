import memoize from 'lodash/memoize';
import noop from 'lodash/noop';
import { EventEmmiterEventName } from '../../__model__/enum/event-emmiter-event-name.enum';
import type { ChatContext } from '../../__model__/public-chat.model';
import { getUserevents } from '../../lib/network/get-userevents';
import { poller } from '../../util/polling';

const getUsereventPoller = memoize((context) =>
  poller(async () => {
    const { getFromPrivateSnapshot, privateEvents } = context;

    return getUserevents(context)
      .then(async (data) => {
        const activeConversationId = getFromPrivateSnapshot(
          'conversation.active.id',
        );
        const currentConversationId = getFromPrivateSnapshot(
          'conversation.current.id',
        );

        const processedData = data.reduce(
          (acum, event) => {
            const id = event.conversationChange.conversationId;
            acum.shouldUpdateActive =
              acum.shouldUpdateActive ||
              id === activeConversationId ||
              id === currentConversationId;

            if (id !== activeConversationId && id !== currentConversationId) {
              acum.idsThatNeedCheckedForActive.add(id);
            }

            return acum;
          },
          {
            shouldUpdateActive: false,
            idsThatNeedCheckedForActive: new Set<string>(),
          },
        );

        privateEvents.triggerConditionally(
          processedData.shouldUpdateActive,
          EventEmmiterEventName.CONVERSATION_UPDATE_ACTIVE,
        );

        processedData.idsThatNeedCheckedForActive.forEach((id) =>
          privateEvents.trigger(
            EventEmmiterEventName.CONVERSATION_CHECK_ID_ACTIVE,
            id,
          ),
        );
      })
      .catch(noop);
  }),
);

export const stopUsereventPolling = (context: ChatContext) =>
  getUsereventPoller(context).stop();

export const startUsereventPolling = (context: ChatContext) => {
  if (context.hasPollingEnabled) {
    getUsereventPoller(context).start();
  }
};

export const setUsereventPollingTime = (
  context: ChatContext,
  time?: number,
) => {
  const { getFromPrivateSnapshot } = context;
  const isActiveNotClosed =
    getFromPrivateSnapshot<boolean | undefined>(
      'conversation.active.isClosed',
    ) === false;
  const fastPollingTime = getFromPrivateSnapshot<number>(
    'omni.polling.usereventFast',
  );
  const slowPollingTime = getFromPrivateSnapshot<number>(
    'omni.polling.usereventSlow',
  );
  const defaultPollingTime = isActiveNotClosed
    ? fastPollingTime
    : slowPollingTime;

  let _timing = typeof time === 'number' ? time : defaultPollingTime;
  _timing = context.hasPollingEnabled ? _timing : -1;

  getUsereventPoller(context).setTime(_timing);
};
