import type { PageData, PageDataConfig } from '@cigna/shared/analytics/core';

const UNSPECIFIED = 'Unspecified';
const DEFAULT_VERSION = 'v1';

export const mapPageInfoConfigToPageInfo = (
  config: PageDataConfig | undefined,
  siteName: string,
  url: string,
): PageData => ({
  pageName: config?.pageName || UNSPECIFIED,
  pageSection: config?.pageSection || UNSPECIFIED,
  pageType: config?.pageType || UNSPECIFIED,
  pageURL: url,
  pageVersion: config?.pageVersion || DEFAULT_VERSION,
  pageAuthenticationType: config?.pageAuthenticationType || 'Authenticated',
  site: siteName,
});
