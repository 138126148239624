// @graph-ignore
import leafIcon from '../generated/leaf-icons.svg';

window.LEAF_ICON_URL = leafIcon;

declare global {
  interface Window {
    LEAF_ICON_URL: string;
  }
}
