import type { APIResponseConversationsEvent } from '../../__model__/api/response/conversation.model';
import type { ServerClassification } from '../../__model__/enum/server-classification';
import getData from '../../util/get-data';

export interface ViewMessageProps {
  conversationId: string | number;
  eventId: string | number;
  serverType: ServerClassification;
}

export const viewMessage = (props: ViewMessageProps) =>
  getData<APIResponseConversationsEvent[]>({
    params: {
      ...props,
      action: 'messageViewed',
    },
  });
