export const updateOrRemoveOnKeyDiff = <T = unknown>(
  obj: T,
  key: string,
  value: unknown,
) => {
  if (
    obj === undefined ||
    obj === null ||
    obj[key as keyof typeof obj] === value
  ) {
    return obj;
  } else if (value === undefined || value === null) {
    const newObj = { ...obj };
    delete newObj[key as keyof typeof newObj];
    return newObj;
  }

  return {
    ...obj,
    [key]: value,
  };
};
