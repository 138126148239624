import type { APIResponseUserevents } from '../../__model__/api/response/userevents';
import { ServerClassification } from '../../__model__/enum/server-classification';
import type { ChatContext } from '../../__model__/public-chat.model';
import getData from '../../util/get-data';

export const getUserevents = async (context: ChatContext) => {
  const timestamp = context.lastUserEventCallTime;
  context.lastUserEventCallTime = Date.now();
  return getData<APIResponseUserevents>({
    params: {
      action: 'userEvent',
      serverType: ServerClassification.live,
      timestamp,
    },
  })
    .then(async (data) => {
      if (!data.length && context.seenUserEvents.size) {
        context.seenUserEvents.clear();
      }
      return data;
    })
    .then(async (data) =>
      data.reduce((acum, event) => {
        if (
          !context.seenUserEvents.has(
            event.conversationChange.conversationEventId,
          )
        ) {
          acum.push(event);
          context.seenUserEvents.add(
            event.conversationChange.conversationEventId,
          );
        }
        return acum;
      }, [] as typeof data),
    );
};
