import { useEffect, type PropsWithChildren, useRef } from 'react';
import { LeafButton } from '@esi/leaf-react';
import styles from './filter-toggle.module.scss';

export interface FilterToggleProps {
  label: string;
  isOpen: boolean;
  onOpen: () => void;
  onClosed: () => void;
  testId: string;
  panelWidth?: string;
}

export function FilterToggle(props: PropsWithChildren<FilterToggleProps>) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      const testId = (event.target as HTMLElement).getAttribute('data-test-id');

      if (
        props.isOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        testId !== `${props.testId}-btn`
      ) {
        closePanel();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [props.isOpen]);

  const closePanel = () => {
    props.onClosed();
  };

  const togglePanel = () => {
    if (props.isOpen) {
      props.onClosed();
    } else {
      props.onOpen();
    }
  };

  return (
    <div className={styles.container}>
      <LeafButton
        data-test-id={`${props.testId}-btn`}
        text={props.label}
        iconName={props.isOpen ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
        iconPosition="after"
        variant="secondary"
        size="sm"
        className={styles.filterBtn}
        onClick={togglePanel}
      />

      {props.isOpen && (
        <div
          className={styles.panel}
          style={{ width: props.panelWidth || '330px' }}
          ref={wrapperRef}
          data-test-id={`${props.testId}-panel`}
        >
          {props.children}
        </div>
      )}
    </div>
  );
}

export default FilterToggle;
