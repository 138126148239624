import type { AgentActivityMessage } from './message-type/agent-activity.model';
import type { AgentTypingMessage } from './message-type/agent-typing.model';
import type { CtaListMessage } from './message-type/cta-list.model';
import type { GroupedStandardMessage } from './message-type/grouped-standard.model';
import type { StandardMessage } from './message-type/standard.model';
import type { SystemMessage } from './message-type/system.model';
import type { WaitMessage } from './message-type/wait.model';
import type { Message, MessgeWithText, ViewableMessage } from './message.model';

export type AnyMessage =
  | AgentActivityMessage
  | AgentTypingMessage
  | CtaListMessage
  | GroupedStandardMessage
  | Message
  | MessgeWithText
  | StandardMessage
  | SystemMessage
  | ViewableMessage
  | WaitMessage;

export enum MessageListModifiyStatus {
  replaced,
  notFound,
  isSame,
  added,
}

export interface MessageList {
  add(msg: AnyMessage, isPinedToEnd?: boolean): MessageListModifiyStatus;
  filter(arg0: (msg: AnyMessage) => boolean): AnyMessage[];
  getTempId(): string;
  list: Readonly<AnyMessage[]>;
  map(arg0: (msg: AnyMessage) => AnyMessage | undefined): this;
  remove(_id: AnyMessage['_id']): boolean;
  replace(
    _id: AnyMessage['_id'],
    msg: AnyMessage | undefined,
    isPinedToEnd?: boolean,
  ): MessageListModifiyStatus;
}
