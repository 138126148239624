interface TempIdGenProps {
  start?: number;
  prefix?: string;
}

const ids = new Set();

/**
 * Get a unique id using the prefix an start
 * @private
 * @generator
 * @param {Object} [param0={}] optional params
 * @param {Number} [param0.start=0] the starting index
 * @param {String} [param0.prefix=temp_] the key prefix
 * @returns {String} ''
 */
export function* tempIdGen({
  start = 0,
  prefix = 'temp_',
}: TempIdGenProps = {}): Generator<string, string, unknown> {
  let index = start;

  /**
   * This is a never ending while so that
   * no matter how many times ths generator
   * is called it will never end
   */
  /* eslint-disable-next-line  @typescript-eslint/no-unnecessary-condition */
  while (true) {
    const key = `${prefix}${index}`;
    index += 1;
    if (!ids.has(key)) {
      ids.add(key);
      yield key;
    }
  }
}
