import type { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { LeafBadge, LeafIconLegacy, LeafLoadingSpinner } from '@esi/leaf-react';
import type { status, variant } from '@esi/leaf-wc/lib/components/badge/badge';
import {
  type WebActionData,
  useTrackPageAction,
  type EventConfigData,
} from '@cigna/shared/react/analytics-util';
import styles from './list-card.module.scss';
export interface ListCardItem {
  headline?: string;
  description?: string;
  external?: boolean;
  link?: string;
  icon?: string;
  last?: boolean; // todo: ideally find way to avoid needing this
  loading?: boolean;
  badge?: {
    text: string;
    status: keyof typeof status;
    variant?: keyof typeof variant;
  };
  testId?: string;
  analytics?: {
    actionData: WebActionData;
    eventConfig?: EventConfigData;
  };
}

interface CardLinkProps {
  external: boolean;
  link?: string;
  track?: () => void;
}

function CardLink(props: PropsWithChildren<CardLinkProps>) {
  if (props.external && props.link) {
    return (
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        className="enac-g-router-link"
        onClick={() => (props.track ? props.track() : null)}
      >
        {props.children}
      </a>
    );
  }
  if (props.link) {
    return (
      <Link
        to={props.link}
        className="enac-g-router-link"
        onClick={() => (props.track ? props.track() : null)}
      >
        {props.children as any}
      </Link>
    );
  }
  return <div>{props.children}</div>;
}

export function ListCard(props: PropsWithChildren<ListCardItem>) {
  const trackPageAction = useTrackPageAction();

  return (
    <CardLink
      link={props.link}
      external={props.external || false}
      track={() =>
        props.analytics
          ? trackPageAction(
              props.analytics.actionData,
              props.analytics.eventConfig,
            )
          : null
      }
    >
      <div
        className={`${styles.cardListItem} ${
          props.last === true ? styles.cardListItemLast : ''
        }`}
        data-test-id={props.testId}
      >
        {(props.icon || props.loading) && (
          <div className={styles.iconLeftWrapper}>
            {props.loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '32px',
                  width: '32px',
                }}
                data-test-id="loading-spinner"
              >
                <LeafLoadingSpinner size="small" />
              </div>
            ) : (
              <LeafIconLegacy className={styles.iconLeft} name={props.icon} />
            )}
          </div>
        )}
        <div className={styles.textWrapper}>
          <div className={styles.headline} data-test-id="list-card-headline">
            {props.loading ? <span>Loading</span> : props.headline}
          </div>
          {props.description && (
            <div className={styles.description}>{props.description}</div>
          )}
          {props.children && (
            <div className={styles.childContent}>{props.children}</div>
          )}
        </div>
        <div className={styles.iconRightWrapper}>
          {props.badge && (
            <LeafBadge
              data-test-id="list-card-badge"
              text={props.badge.text}
              status={props.badge.status}
              variant={props.badge.variant}
            />
          )}
          {props.link && (
            <LeafIconLegacy
              className={styles.iconRight}
              name={props.external ? 'external-file' : 'keyboard-arrow-right'}
            />
          )}
        </div>
      </div>
    </CardLink>
  );
}

export default ListCard;
