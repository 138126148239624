import type { MessageType } from '../enum/message-type.enum';
import type { WaitMessageTimingKey } from '../enum/wait-message-timing-key.enum';
import type { Message } from '../message.model';

export const WAIT_MSG_ID = 'wait';

interface WaitMessageTiming {
  time: Date;
  key: WaitMessageTimingKey;
}

export interface WaitMessage extends Message {
  _id: 'wait';
  type: MessageType.wait;
  timing: WaitMessageTiming[];
}
