import type {
  FileMetaData,
  StandardApiResponse,
} from '@cigna/enac-provider-web/shared/util';
import { useMutation } from '@cigna/shared/react/react-query-web-data-access';

export interface ClaimAttachmentsMetadataRequestBody {
  serviceReferenceNumber: string;
  attachmentType: string;
  files: FileMetaData[];
  claimId?: string;
}

export const usePostClaimAttachmentsMetadataApi = () =>
  useMutation<StandardApiResponse, ClaimAttachmentsMetadataRequestBody>({
    method: 'POST',
    url: `/claims/attachments/v1/pended/upload`,
  });
