import { useState } from 'react';
import {
  LeafIconLegacy,
  LeafInlineAlert,
  LeafTextPassage,
} from '@esi/leaf-react';
import styles from './toast-alert.module.scss';

export interface ToastMessage {
  status: 'success' | 'error';
  message: string;
  showMessage: boolean;
}

export interface ToastAlertProps extends ToastMessage {
  hideCloseIcon?: boolean;
  onClose?: () => void;
}

export function ToastAlert(props: ToastAlertProps) {
  const handleOnClose = () => {
    props.onClose && props.onClose();
  };
  return (
    <>
      {props.showMessage && (
        <LeafInlineAlert
          {...{
            status: props.status,
            isError: props.status === 'error',
            isSuccess: props.status === 'success',
            iconName: props.status === 'error' ? 'error-alt' : 'success',
          }}
          className={`${styles.message} ${
            props.status === 'error' ? styles.error : ''
          }`}
          data-test-id="toast-alert"
        >
          <LeafTextPassage>
            {props.message}
            {!!!props.hideCloseIcon && (
              <LeafIconLegacy
                name="close"
                data-test-id="toast-alert-close-btn"
                className={styles.closeIcon}
                onclick={handleOnClose}
              />
            )}
          </LeafTextPassage>
        </LeafInlineAlert>
      )}
    </>
  );
}

export default ToastAlert;
