import type { StandardApiResponse } from '@cigna/enac-provider-web/shared/util';
import { useMutation } from '@cigna/shared/react/react-query-web-data-access';

export interface ValidatePasswordResponse extends StandardApiResponse {
  data?: validatePasswordData;
}

export interface validatePasswordData {
  score: number;
  warnings: string;
  suggestions: string[];
}

export interface ValidatePasswordRequest {
  firstName?: string;
  lastName?: string;
  dob?: string;
  ssoId: string;
  userPwd: string;
}

export const useValidatePasswordApi = () =>
  useMutation<ValidatePasswordResponse, ValidatePasswordRequest>({
    url: `/public/credentials/v1/validation`,
    method: 'POST',
    isPublicRequest: true,
    params: {
      consumerId: 'evn-providercare-web',
    },
  });
