import React from 'react';
import {
  QueryClientProvider as BaseQueryClientProvider,
  QueryClient,
} from '@tanstack/react-query';

export const queryClient = new QueryClient();

interface QueryClientProviderWebProps {
  children: React.ReactNode;
}

export const QueryClientProviderWeb = ({
  children,
}: QueryClientProviderWebProps) => (
  <BaseQueryClientProvider client={queryClient}>
    {children}
  </BaseQueryClientProvider>
);
