import { useCallback, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { OmniContext } from '@cigna/react/chat/data-access';

type Auth0LogoutOptions = Required<
  Parameters<ReturnType<typeof useAuth0>['logout']>
>[0];

interface LogoutOptions extends Auth0LogoutOptions {
  isCausedByError?: boolean;
}

export function useLogout() {
  const { logout: auth0Logout } = useAuth0();
  const { logout: chatLogout } = useContext(OmniContext);

  const logout = useCallback(
    (options: LogoutOptions = {}) => {
      const { isCausedByError = false, ...rest } = options;

      const promiseList = [];
      promiseList.push(isCausedByError ? Promise.resolve() : chatLogout());

      window.sessionStorage.removeItem('hasLoggedIn');
      return Promise.allSettled(promiseList).then(async () =>
        auth0Logout(rest),
      );
    },
    [auth0Logout, chatLogout],
  );

  return {
    logout,
  };
}

export default useLogout;
