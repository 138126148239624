import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { PageData } from '@cigna/shared/analytics/core';
import { useAnalytics } from '../context/AnalyticsProvider';
import { useTrackPageAction } from './useTrackPageAction';

export const useTrackHasLoggedIn = () => {
  const trackPageAction = useTrackPageAction();
  const { isAuthenticated } = useAuth0();
  const analytics = useAnalytics();
  const config = analytics?.getConfig();
  const loginSuccessData = config?.loginSuccessData;
  const additionalUserInfo = config?.additionalUserInfo;

  useEffect(() => {
    const hasLoggedIn = sessionStorage.getItem('hasLoggedIn') ?? null;

    if (
      !hasLoggedIn &&
      isAuthenticated &&
      loginSuccessData &&
      additionalUserInfo?.isFeaturesSuccess
    ) {
      const site = config.name || '';
      const pageData: PageData = {
        site,
        pageName: '',
        pageAuthenticationType: 'Authenticated',
        pageVersion: 'v1',
        ...loginSuccessData.pageData,
      };
      trackPageAction(
        loginSuccessData.loginSuccessAction,
        {
          includeAutoTrackingData: true,
          pageData,
        },
        {
          userInfo: additionalUserInfo,
        },
      );

      sessionStorage.setItem('hasLoggedIn', 'true');
    }
  }, [
    trackPageAction,
    isAuthenticated,
    config,
    loginSuccessData,
    additionalUserInfo?.isFeaturesSuccess,
  ]);
};
