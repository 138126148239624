import type { ApiResponseMetadata } from '@cigna/enac-provider-web/shared/util';

export const metadata200Mock: ApiResponseMetadata = {
  serviceReferenceId: '1111-1111-111-1111',
  outcome: {
    status: 200,
    type: 'OK',
    message: 'Success',
    code: 0,
    additionalDetails: [],
  },
};

export const metadata404Mock: ApiResponseMetadata = {
  serviceReferenceId: '1111-1111-111-1111',
  outcome: {
    status: 404,
    type: 'OK',
    message: 'Success',
    code: 0,
    additionalDetails: [],
  },
};
