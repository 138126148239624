import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useEnvironment } from '@cigna/shared/react/environment-provider-util';

export function Impersonate() {
  const { error, loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
  const { environment } = useEnvironment();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) {
      loginWithRedirect({
        appState: {
          returnTo: environment.auth0.impersonationReturnTo,
        },
        authorizationParams: {
          connection: environment.auth0.impersonationConnection,
          redirect_uri: `${environment.auth0.redirect_uri}/public/impersonate`,
        },
      });
    }
    if (isAuthenticated) {
      navigate(environment.auth0.impersonationReturnTo);
    }
  }, [
    error,
    navigate,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    environment.auth0.impersonationReturnTo,
    environment.auth0.impersonationConnection,
    environment.auth0.redirect_uri,
  ]);

  return <div></div>;
}

export default Impersonate;
