/**
 * Disabling max classes per file because
 * - Id is a utility class that is only to be used
 * by the WeakMap in the MessageList
 * - Neither class is the public api for what is
 * intended to be exported by this file.
 */
/* eslint-disable max-classes-per-file */

import { differenceInMinutes, isEqual as isDateEqual } from 'date-fns';
import isEqual from 'lodash/isEqual';
import { MessageType } from '../__model__/enum/message-type.enum';
import {
  MessageListModifiyStatus,
  type AnyMessage,
  type MessageList as MessageListDef,
} from '../__model__/message-list.model';
import type { GroupedStandardMessage } from '../__model__/message-type/grouped-standard.model';
import type { StandardMessage } from '../__model__/message-type/standard.model';
import type {
  MessgeWithText,
  ViewableMessage,
} from '../__model__/message.model';
import { tempIdGen } from './temp-id-generator';

class Id {
  _id: string;
  _isPinnedToEnd: boolean;
  constructor(_id: string, isPinedToEnd?: boolean) {
    this._id = _id;
    this._isPinnedToEnd = isPinedToEnd ?? false;
  }
}

const messageTypeEqulaityChecker: Record<
  MessageType,
  (A: AnyMessage | undefined, B: AnyMessage | undefined) => boolean
> = {
  [MessageType.agentActivity]: isEqual,
  [MessageType.agentIsTyping]: isEqual,
  [MessageType.cta_list]: isEqual,
  [MessageType.standard](msgA, msgB): boolean {
    try {
      return !!(
        msgA &&
        msgB &&
        msgA._id === msgB._id &&
        isDateEqual(msgA.date, msgB.date) &&
        (msgA as MessgeWithText).message === (msgB as MessgeWithText).message &&
        msgA.participant?._id === msgB.participant?._id &&
        msgA.type === msgB.type &&
        typeof (msgA as ViewableMessage).onView ===
          typeof (msgB as ViewableMessage).onView
      );
    } catch (e) {
      return false;
    }
  },
  [MessageType.system]: isEqual,
  [MessageType.wait]: isEqual,
  [MessageType.groupedStandard](msgA, msgB): boolean {
    try {
      return !!(
        msgA &&
        msgB &&
        msgA._id === msgB._id &&
        msgA.type === msgB.type &&
        isDateEqual(msgA.date, msgB.date) &&
        msgA.participant?._id === msgB.participant?._id &&
        (msgA as GroupedStandardMessage).message.length ===
          (msgB as GroupedStandardMessage).message.length &&
        (msgA as GroupedStandardMessage).message.every((value, index) =>
          messageTypeEqulaityChecker[MessageType.standard](
            value,
            (msgB as GroupedStandardMessage).message[index],
          ),
        ) &&
        typeof (msgA as ViewableMessage).onView ===
          typeof (msgB as ViewableMessage).onView
      );
    } catch (e) {
      return false;
    }
  },
};

export class MessageList implements MessageListDef {
  /**
   * @generator
   * @static
   */
  static tempId = tempIdGen({ prefix: 'tempMsg_' });

  /**
   * @private
   * @type WeakMap<Id, AnyMessage>
   */
  #messages = new WeakMap<Id, AnyMessage>();

  /**
   * @private
   * @type Id[]
   */ #messageOrder: Id[] = [];

  /**
   * @private
   * @type AnyMessage[]
   */
  #list: AnyMessage[] = [];

  /**
   * @property {AnyMessage[]} list the list of messages
   */
  get list() {
    return this.#list;
  }

  /**
   * updatees the list of messages
   * @private
   */
  private updateList() {
    const pinnedToEnd: AnyMessage[] = [];

    const newList = this.#messageOrder
      .reduce((acum: AnyMessage[], id: Id) => {
        const value = this.#messages.get(id);
        if (!value) {
          return acum;
        }

        if (id._isPinnedToEnd) {
          pinnedToEnd.unshift(value);
          return acum;
        }

        if (acum.length) {
          const prevValue = acum[acum.length - 1];
          if (
            prevValue &&
            MessageType.standard === prevValue.type &&
            MessageType.standard === value.type &&
            prevValue.participant?._id === value.participant?._id &&
            differenceInMinutes(value.date, prevValue.date, {
              roundingMethod: 'floor',
            }) === 0
          ) {
            const msg: GroupedStandardMessage = {
              ...prevValue,
              type: MessageType.groupedStandard,
              message: [prevValue as StandardMessage, value as StandardMessage],
            };
            acum[acum.length - 1] = msg;
          } else if (
            prevValue &&
            MessageType.groupedStandard === prevValue.type &&
            MessageType.standard === value.type &&
            prevValue.participant?._id === value.participant?._id &&
            differenceInMinutes(value.date, prevValue.date, {
              roundingMethod: 'floor',
            }) === 0
          ) {
            (prevValue as GroupedStandardMessage).message.push(
              value as StandardMessage,
            );
          } else {
            acum.push(value);
          }
        } else {
          acum.push(value);
        }

        return acum;
      }, [])
      .concat(pinnedToEnd);

    /**
     * to prevent thrashing, only change the ref that this.#list
     * points to when the new list has a differnt length or the
     * two list contains differ elements by value and not ref
     */
    if (
      newList.length !== this.#list.length ||
      (newList.length === this.#list.length &&
        !newList.every((newEntery, index) => {
          const oldEntery = this.#list[index];
          return (
            newEntery === oldEntery ||
            messageTypeEqulaityChecker[newEntery.type](newEntery, oldEntery)
          );
        }))
    ) {
      this.#list = newList;
    }
  }

  /**
   * Get a filtered list of Messages back.
   * Note This will not group any messages like what you would get out of `list`.
   * @param {Function} fn function that gets a Message and makes a determindation if
   *                      it should be included in the resulting list of messages;
   *                      If it returns false the Message is not included;
   *                      If it returns true the Message will be included;
   * @returns {AnyMessage[]} a list of messages that meet the criteria set for in the fn
   */
  filter(fn: (msg: AnyMessage) => boolean, thisArg?: unknown): AnyMessage[] {
    return this.#messageOrder
      .flatMap((id) => {
        const value = this.#messages.get(id);
        return value ? [value] : [];
      })
      .filter(fn, thisArg);
  }

  map(fn: (msg: AnyMessage) => AnyMessage | undefined): this {
    this.#messageOrder
      .flatMap((id) => {
        const value = this.#messages.get(id);
        return value ? [value] : [];
      })
      .forEach((msg) => {
        const results = fn(msg);
        if (typeof results === 'undefined') {
          this.remove(msg._id);
        } else {
          this.replace(msg._id, results);
        }
      });

    return this;
  }

  /**
   * get a unique tempoary id for a message
   * @returns {string} a unique tempoary id for a message
   */
  getTempId(): string {
    return MessageList.tempId.next().value;
  }

  /**
   * Add a Message to the list of messages
   * @param {AnyMessage} msg the message to be added
   * @param  {boolean} [isPinedToEnd=false] if the Message is pined to the end of the message list
   * @returns {MessageListModifiyStatus} true if the message was added, otherwise false
   */
  add(msg: AnyMessage, isPinedToEnd?: boolean) {
    let modifyStatus: MessageListModifiyStatus = this.replace(
      msg._id,
      msg,
      isPinedToEnd,
    );

    if (modifyStatus === MessageListModifiyStatus.notFound) {
      const id = new Id(msg._id, isPinedToEnd);
      this.#messageOrder.push(id);
      this.#messages.set(id, msg);
      this.updateList();
      modifyStatus = MessageListModifiyStatus.added;
    }

    return modifyStatus;
  }

  /**
   * Removes the message with the supplied id
   * @param {string} _id the id of the message to be removed
   * @returns {boolean} true if the message was removed, otherwise false
   */
  remove(_id: AnyMessage['_id']): boolean {
    let didFindId = false;
    this.#messageOrder = this.#messageOrder.filter((id: Id) => {
      if (id._id === _id) {
        didFindId = true;
        return false;
      }
      return true;
    });
    this.updateList();
    return didFindId;
  }

  /**
   * Replaces a message
   * @param {string} _id the id of the msg to be replaced
   * @param {AnyMessage} msg the message that is replacing the other
   * @param {boolean=false} [isPinedToEnd=false] If the replacement message should be pined to the end of the message list
   * @returns {MessageListModifiyStatus} MessageListModifiyStatus
   */
  replace(_id: AnyMessage['_id'], msg: AnyMessage, isPinedToEnd?: boolean) {
    let modifiyStatus: MessageListModifiyStatus =
      MessageListModifiyStatus.notFound as MessageListModifiyStatus;

    const newId = new Id(msg._id);
    let oldId;
    this.#messageOrder = this.#messageOrder.map((id: Id) => {
      if (id._id === _id) {
        oldId = id;
        newId._isPinnedToEnd =
          typeof isPinedToEnd !== 'boolean'
            ? oldId._isPinnedToEnd
            : isPinedToEnd;
        const oldMsg: AnyMessage | undefined = this.#messages.get(id);
        const isSame =
          messageTypeEqulaityChecker[msg.type](msg, oldMsg) &&
          newId._isPinnedToEnd === oldId._isPinnedToEnd;

        modifiyStatus = isSame
          ? MessageListModifiyStatus.isSame
          : MessageListModifiyStatus.replaced;
        return isSame ? id : newId;
      }
      return id;
    });

    if (modifiyStatus === MessageListModifiyStatus.replaced) {
      this.#messages.set(newId, msg);
      this.updateList();
    }

    return modifiyStatus;
  }
}

export default MessageList;
