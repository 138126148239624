import type { StandardApiResponse } from '@cigna/enac-provider-web/shared/util';
import { useQuery } from '@cigna/shared/react/react-query-web-data-access';

export interface NavResponse extends StandardApiResponse {
  navigation: MainNavLink[];
}

interface BaseLink {
  title: string;
  path: string;
  identifier: string;
  openExternal: boolean;
}
export interface MainNavLink extends BaseLink {
  children?: BaseLink[];
}

export const useNavigationApi = () =>
  useQuery<NavResponse>(
    {
      url: `/identity/v1/navigation/primary`,
    },
    { retry: 1, staleTime: Infinity },
  );
