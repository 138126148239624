import type { APIResponseConversations } from '../../__model__/api/response/conversation.model';
import type { Conversation } from '../../__model__/conversation.model';
import type { ServerClassification } from '../../__model__/enum/server-classification';
import getData from '../../util/get-data';
import {
  type GetConversationWrapperProps,
  getConversationWrapper,
} from './get-conversation-wrapper';

interface GetConversationProps
  extends Omit<GetConversationWrapperProps, 'requestHandler'> {
  conversationId: Conversation['id'];
  serverType: ServerClassification;
}

export async function getConversation(
  props: GetConversationProps,
): ReturnType<typeof getConversationWrapper> {
  const { conversationId, serverType } = props;

  return getConversationWrapper({
    ...props,
    requestHandler: ({ abortController }) =>
      getData<APIResponseConversations>({
        abortController,
        params: {
          action: 'getConversation',
          conversationId,
          serverType,
        },
      }),
  });
}
