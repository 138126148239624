import type { AxiosError, AxiosResponse } from 'axios';
import type { ServerClassification } from '../__model__/enum/server-classification';
import chatAxios from './chat-axios';

export interface DataFetcherProps<D> {
  params: {
    serverType: ServerClassification;
    action: string;
    [key: string]: string | number | undefined;
  };
  data?: D;
  retries?: number;
  abortController?: AbortController;
}

export const getData = async <T, D = unknown>({
  data,
  params,
  retries = 0,
  abortController,
}: DataFetcherProps<D>): Promise<T> =>
  chatAxios<T, AxiosResponse<T>, D>({
    params,
    data,
    signal: abortController?.signal,
  })
    .then(async (response) => response.data as T)
    .catch(async (error: AxiosError) =>
      retries > 0
        ? getData({ data, params, retries: retries - 1 })
        : Promise.reject(error.response?.data),
    );

export default getData;
